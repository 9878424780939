import { cloneDeep, debounce, isEqual, set as setValueInObject } from 'lodash';
import { useEffect } from 'react';
import { create } from 'zustand';

import type { Robot } from '@sb/remote-control/types';
import { ExternalControlSettings } from '@sb/routine-runner';
import type { DottedPathsOf, PropertyAtDottedPath } from '@sb/utilities';
import {
  onGetExternalControlSettings,
  onGetRoutineSummaries,
  updateExternalControlSettings,
} from '@sbrc/services';

type View = 'summary' | '24VIO' | 'defaultRoutine';

interface ExternalControlModalStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  settings: ExternalControlSettings;
  hasUnsavedChanges: boolean;
  setField<P extends DottedPathsOf<ExternalControlSettings>>(
    path: P,
    value: PropertyAtDottedPath<ExternalControlSettings, P>,
  ): void;
  currentView: View;
  setCurrentView: (view: View) => void;
  allRoutines: Record<string, string>;
  robotID: string | null;
  ioInputs: Robot.IOPort[];
  ioOutputs: Robot.IOPort[];
}

export const useExternalControlModalStore = create<ExternalControlModalStore>(
  (set, get) => {
    const updateSettings = debounce(async () => {
      const state = get();

      if (!state.robotID || !state.hasUnsavedChanges) {
        return;
      }

      // reset this *before* calling update to avoid resetting erroneously if a change is made *during* the update
      set({ hasUnsavedChanges: false });

      try {
        await updateExternalControlSettings(state.robotID, state.settings);
      } catch (error) {
        // todo
      }
    }, 200);

    return {
      isOpen: false,
      setIsOpen: (isOpen) => set({ isOpen }),
      settings: ExternalControlSettings.parse({}),
      hasUnsavedChanges: false,
      setField(path, value) {
        const prevSettings = get().settings;
        const settings = cloneDeep(prevSettings);
        setValueInObject(settings, path, value);

        if (!isEqual(prevSettings, settings)) {
          set({ settings, hasUnsavedChanges: true });
          updateSettings();
        }
      },
      currentView: 'summary',
      setCurrentView: (currentView) => set({ currentView }),
      allRoutines: {},
      robotID: null,
      ioInputs: [],
      ioOutputs: [],
    };
  },
);

export function useInitializeExternalControlModalStore({
  isOpen,
  robotID,
  ioInputs,
  ioOutputs,
}: {
  isOpen: boolean;
  robotID: string;
  ioInputs: Robot.IOPort[];
  ioOutputs: Robot.IOPort[];
}) {
  useEffect(() => {
    if (isOpen) {
      return undefined;
    }

    const unsubscribeSettings = onGetExternalControlSettings(
      robotID,
      (settings) => {
        useExternalControlModalStore.setState({ robotID, settings });
      },
    );

    return () => {
      unsubscribeSettings();
    };
  }, [robotID, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const unsubscribeRoutines = onGetRoutineSummaries((routines) => {
      useExternalControlModalStore.setState({
        allRoutines: Object.fromEntries(routines.map((r) => [r.id, r.name])),
      });
    });

    return () => {
      unsubscribeRoutines();
    };
  }, [isOpen]);

  useEffect(() => {
    useExternalControlModalStore.setState({ ioInputs, ioOutputs });
  }, [ioInputs, ioOutputs]);
}
