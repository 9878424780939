/* eslint-disable react/destructuring-assignment */

import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState, useRobotPayloadState } from '@sbrc/hooks';

import { OnRobot3FG15HomeWidgetInner } from './OnRobot3FG15HomeWidgetInner';

export function OnRobot3FG15HomeWidget(args: Integrations.HomeWidgetProps) {
  const routineRunnerPayload = useRobotPayloadState({});

  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot: false },
    'OnRobot3FG15',
  );

  if (!routineRunnerGripperState || routineRunnerPayload === null) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a 3FG15
        gripper is properly mounted and connected.
      </p>
    );
  }

  if (!routineRunnerGripperState.isCalibrationOk) {
    return (
      <p>
        Calibration is not OK. This also happens when the 2FG7 is attached
        instead of a 3FG15. If you're trying to use a 2FG7, go to the equipment
        tab, remove the 3FG15, and add the 2FG7."
      </p>
    );
  }

  return (
    <OnRobot3FG15HomeWidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
    />
  );
}
