import type { Integrations, RoutineVariable } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../../utils/frontend/getGripperImplementationDefaults';

import { ORVGP20_SHORTNAME } from './constants';
import { OnRobotVGP20EquipmentManager } from './equipment-manager/OnRobotVGP20EquipmentManager';
import { OnRobotVGP20Configuration } from './types';
import { OnRobotVGP20HomeWidget } from './widget/OnRobotVGP20HomeWidget';
import { OnRobotVGP20Widget } from './widget/OnRobotVGP20Widget';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    // TODO
  ];

export const onRobotVGP20Implementation: Integrations.IntegrationFrontend<'OnRobotVGP20'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'equipmentVacuum',
    imageSrc: 'OnRobotVGP20.png',
    Widget: OnRobotVGP20Widget,
    HomeWidget: OnRobotVGP20HomeWidget,
    getIntegrationVariables: () => INTEGRATION_VARIABLES,
    getSupportedSteps: () => ['ActuateVacuum'],
    getDisplayName: () => {
      return 'OnRobot VGP20';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => ORVGP20_SHORTNAME,
    getDefaultItem: () =>
      OnRobotVGP20Configuration.parse({ kind: 'OnRobotVGP20' }),
    EquipmentManagerEditForm: OnRobotVGP20EquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobotVGP20') {
        return undefined;
      }

      if (!state.isConnected) {
        return 'disconnected';
      }

      if (state.error) {
        return { kind: 'error', message: state.error };
      }

      return state.isBusy ? 'busy' : 'idle';
    },
    getTCPOffsetOptions: (config) => {
      return [
        {
          tcpOffsetOption: 1,
          name: config.name,
          iconKind: 'equipmentVacuum',
        },
      ];
    },
  };
