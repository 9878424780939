import cx from 'classnames';
import { Fragment } from 'react';

import {
  SettingsGroup,
  SettingsGroupItem,
  Button,
  Input,
  RichSelect,
  Menu,
  MenuItem,
} from '@sb/design-system';

import { MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT } from '../constants';
import type {
  ModbusTCPRegisterData,
  ModbusTCPServerRegisterFormat,
  ModbusTCPServerRegisterKind,
} from '../types';
import { convertRegisterTypeToDisplayName, isCoilRegister } from '../util';

const HEXADECIMAL_BASE_VALUE = 16;

function convertHexInputToNumber(hex: string): number {
  if (hex.startsWith('0x') || hex === '') {
    return NaN;
  }

  return parseInt(hex, HEXADECIMAL_BASE_VALUE);
}

function isValidHexadecimal(input: string) {
  if (input === '') return false;

  const isValidHex = /^[0-9a-f]+$/i.test(input.toLowerCase());

  return isValidHex;
}

const registerKindOptions: Array<{
  name: string;
  value: ModbusTCPServerRegisterKind;
}> = [
  { name: 'Holding register', value: 'holdingRegister' },
  { name: 'Input register', value: 'inputRegister' },
  { name: 'Status coil', value: 'statusCoil' },
  { name: 'Input coil', value: 'inputCoil' },
];

interface ModbusTCPServerRegisterFieldListProps {
  registerFieldList: Array<ModbusTCPRegisterData>;
  isFormDisabled: boolean;
  removeRegisterFieldRow: (id: string) => void;
  onChange: (value: ModbusTCPRegisterData) => void;
  setIsFieldValid: (field: string) => (isValid: boolean) => void;
}

export function ModbusTCPServerRegisterFieldList({
  registerFieldList,
  isFormDisabled,
  removeRegisterFieldRow,
  onChange,
  setIsFieldValid,
}: ModbusTCPServerRegisterFieldListProps) {
  const updateRegisterDataFormat = (
    kind: ModbusTCPServerRegisterKind,
    format: ModbusTCPServerRegisterFormat,
  ) => {
    if (isCoilRegister(kind)) {
      return 'bit';
    }

    // set back to default when changing back to non coil from coil type
    if (!isCoilRegister(kind) && format === 'bit') {
      return MODBUS_TCP_SERVER_REGISTER_FORMAT_DEFAULT;
    }

    return format;
  };

  return (
    <>
      {registerFieldList.map((register) => {
        const isValidOffset = !Number.isNaN(register.offset);

        return (
          <Fragment key={register.id}>
            <SettingsGroup className="tw-mb-32">
              <SettingsGroupItem>
                <div className="tw-flex-1">Name</div>
                <Input
                  variant="Gray"
                  alignment="Center"
                  className="tw-flex-1"
                  disabled={isFormDisabled}
                  data-testid="input-register-name"
                  value={register.name}
                  onChange={(e) => {
                    onChange({
                      ...register,
                      name: e.target.value,
                    });
                  }}
                />
              </SettingsGroupItem>

              <SettingsGroupItem>
                <div className="tw-flex-1">Type</div>
                <RichSelect
                  className={cx('tw-text-label-secondary')}
                  data-testid="select-register-type"
                  disabled={isFormDisabled}
                  options={
                    <Menu className={cx('tw-max-h-[240px]')}>
                      {registerKindOptions.map((kind) => (
                        <MenuItem
                          key={kind.value}
                          onClick={() => {
                            onChange({
                              ...register,
                              type: kind.value,
                              format: updateRegisterDataFormat(
                                kind.value,
                                register.format,
                              ),
                            });
                          }}
                          secondaryIconKind={
                            convertRegisterTypeToDisplayName(register.type) ===
                            kind.name
                              ? 'checkmark'
                              : 'blank'
                          }
                        >
                          {kind.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  }
                >
                  {convertRegisterTypeToDisplayName(register.type)}
                </RichSelect>
              </SettingsGroupItem>

              <SettingsGroupItem>
                <div className="tw-flex-1">Offset</div>
                <Input
                  variant="Gray"
                  alignment="Center"
                  className={cx(
                    'tw-flex-1',
                    !isValidOffset && 'tw-outline-error',
                  )}
                  data-testid="input-register-offset"
                  disabled={isFormDisabled}
                  value={
                    Number.isNaN(register.offset)
                      ? ''
                      : register.offset
                          .toString(HEXADECIMAL_BASE_VALUE)
                          .toUpperCase()
                  }
                  onChange={(e) => {
                    const offset = e.target.value;

                    setIsFieldValid('offset')(isValidHexadecimal(offset));

                    onChange({
                      ...register,
                      offset: convertHexInputToNumber(offset),
                    });
                  }}
                />
              </SettingsGroupItem>

              <SettingsGroupItem>
                <div className="tw-flex-1">Format</div>
                <Input
                  variant="Gray"
                  alignment="Center"
                  className="tw-flex-1"
                  data-testid="select-register-data-format"
                  value={register.format}
                  disabled
                />
              </SettingsGroupItem>

              <SettingsGroupItem>
                <Button
                  color="Red"
                  className="tw-flex-1 tw-my-12 tw-rounded-10 tw-bg-red-10 dark:tw-bg-red-100 enabled:hover:tw-brightness-90"
                  onClick={() => {
                    removeRegisterFieldRow(register.id);
                  }}
                >
                  Remove
                </Button>
              </SettingsGroupItem>
            </SettingsGroup>
          </Fragment>
        );
      })}
    </>
  );
}
