import cx from 'classnames';

import { Button, Icon } from '@sb/design-system';
import type { Robot, Routine } from '@sb/remote-control/types';
import {
  useEmergencyStopRobot,
  useFeatureFlag,
  useIsRobotConnected,
  useROSControl,
} from '@sbrc/hooks';

import { ExternalControlModal } from '../external-control/ExternalControlModal';
import { NotificationsHeaderButton } from '../notifications/NotificationsHeaderButton';
import { RobotBadge } from '../robot-badge/RobotBadge';
import { useSoftwareBuildData } from '../software-update';

import { PlayPauseButton } from './PlayPauseButton';

interface AppBarProps {
  robot?: Robot.ConvertedResponse | null;
  routine?: Routine.ConvertedResponse | null;
  isVizbot?: boolean;
  children?: React.ReactNode;
  isRoutinePage?: boolean;
  isPreflightChecksPage?: boolean;
}

export default function AppBar({
  children,
  robot,
  isVizbot,
  routine,
  isRoutinePage,
  isPreflightChecksPage,
}: AppBarProps) {
  // robot data
  const isRobotConnected = useIsRobotConnected({});
  const { emergencyStopRobot } = useEmergencyStopRobot({});

  const isRosControlEnabled = useFeatureFlag('rosControl');
  const setROSControlEnabled = useROSControl();

  // build data
  const buildData = useSoftwareBuildData({
    shouldReloadOnActiveBuildIdChange: true,
  });

  return (
    <footer
      className={cx(
        'tw-fixed',
        'tw-bottom-0',
        'tw-left-0',
        'tw-right-0',
        'tw-h-[--page--app-bar-height]',
        'tw-surface-elevated',
        'tw-flex',
        'tw-items-center',
        'tw-border-t',
        'tw-border-metal-30',
        'dark:tw-border-divider-primary',
        'tw-z-20',
        'tw-gap-16',
        'tw-box-content',
      )}
    >
      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pl-16',
          'tw-overflow-hidden',
          'tw-whitespace-nowrap',
          'tw-justify-end',
        )}
      >
        {robot && (
          <RobotBadge robot={robot} buildData={buildData} isVizbot={isVizbot} />
        )}

        <NotificationsHeaderButton />

        <div className="tw-flex-1" />
      </div>

      <div className="tw-flex-none">
        <Button
          disabled={!isRobotConnected}
          variant="Filled"
          color="Red"
          onClick={() => emergencyStopRobot()}
          data-testid="app-header-estop-button"
        >
          <Icon kind="circleSlash" />
          <span>E-Stop</span>
        </Button>
      </div>

      <div
        className={cx(
          'tw-flex',
          'tw-flex-1',
          'tw-items-center',
          'tw-gap-16',
          'tw-pr-16',
          'tw-overflow-hidden',
        )}
      >
        <div className="tw-flex-1" />

        {isRosControlEnabled && (
          <>
            <Button onClick={() => setROSControlEnabled(true)}>ROS2 On</Button>
            <Button onClick={() => setROSControlEnabled(false)}>
              ROS2 Off
            </Button>
          </>
        )}

        {children}

        {robot && (
          <PlayPauseButton
            robot={robot}
            routine={routine}
            isRoutinePage={isRoutinePage}
            isPreflightChecksPage={isPreflightChecksPage}
          />
        )}
      </div>

      {robot && <ExternalControlModal robot={robot} />}
    </footer>
  );
}
