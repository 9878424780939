import type { OnRobot3FG15State } from '@sb/integrations/implementations/OnRobot3FG15/types';
import type { Integrations } from '@sb/remote-control/types';

import { DualOnRobot3FG15HomeWidgetInner } from './DualOnRobot3FG15HomeWidgetInner';

interface DualOnRobot3FG15HomeWidgetProps {
  routineRunnerPayload: number | null;
  routineRunnerGripperState: OnRobot3FG15State;
  args: Integrations.HomeWidgetProps;
  summaryStatus: Integrations.SummaryStatus | null;
}

export function DualOnRobot3FG15HomeWidget({
  routineRunnerPayload,
  routineRunnerGripperState,
  args,
  summaryStatus,
}: DualOnRobot3FG15HomeWidgetProps) {
  if (
    !routineRunnerGripperState ||
    routineRunnerPayload === null ||
    summaryStatus === null
  ) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a 3FG15
        gripper is properly mounted and connected.
      </p>
    );
  }

  if (!routineRunnerGripperState.isCalibrationOk) {
    return (
      <p>
        Calibration Error. This occurs when the gripper is misconfigured, or a
        2FG7 is attached instead of a 3FG15. If you're trying to use a 2FG7, go
        to the equipment tab, remove the 3FG15, and add the 2FG7.
      </p>
    );
  }

  return (
    <DualOnRobot3FG15HomeWidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
      summaryStatus={summaryStatus}
    />
  );
}
