import base62Random from 'base62-random';
import cx from 'classnames';
import isValidHostname from 'is-valid-hostname';
import { useCallback, useMemo } from 'react';

import {
  SettingsGroup,
  SettingsGroupItem,
  NumberInput,
  Input,
  Icon,
  Button,
} from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import {
  MODBUS_TCP_SERVER_REGISTER_DEFAULT,
  ONROBOT_TCP_PORT_DEFAULT,
} from '../constants';
import type { ModbusTCPRegisterData } from '../types/types';

import { ModbusTCPServerRegisterFieldList } from './ModbusTCPServerRegisterFieldList';

export function ModbusTCPServerEquipmentManager({
  item,
  isFormDisabled,
  onUpdate,
  setIsFieldValid,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'ModbusTCPServer') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const isHostValid = isValidHostname(item.connectionOptions.host);

  const registerList: Array<ModbusTCPRegisterData> = useMemo(
    () => item.registers ?? [],
    [item.registers],
  );

  const addRegisterFieldRow = () => {
    const registerId = base62Random(6);

    const defaultRegisterData: ModbusTCPRegisterData = {
      ...MODBUS_TCP_SERVER_REGISTER_DEFAULT,
      id: registerId,
    };

    let registerFieldList: Array<ModbusTCPRegisterData> = [];

    if (item.registers) {
      registerFieldList = [...item.registers];
    }

    registerFieldList.push(defaultRegisterData);

    onUpdate({ ...item, registers: registerFieldList });
  };

  const removeRegisterFieldRow = (registerId: string) => {
    const registerDataList = [...registerList];

    const currentIndex = registerDataList.findIndex(
      (register) => register.id === registerId,
    );

    registerDataList.splice(currentIndex, 1);

    onUpdate({
      ...item,
      registers: registerDataList,
    });
  };

  const onRegisterFieldChange = useCallback(
    (registerValue: ModbusTCPRegisterData) => {
      const regList = [...registerList];

      const currentIndex = regList.findIndex(
        (reg) => reg.id === registerValue.id,
      );

      regList[currentIndex] = { ...regList[currentIndex], ...registerValue };

      onUpdate({
        ...item,
        registers: regList,
      });
    },
    [item, onUpdate, registerList],
  );

  return (
    <>
      <div>
        <SettingsGroup>
          <SettingsGroupItem>
            <div className={cx('tw-flex-1')}>IP address</div>
            <Input
              placeholder="IP address"
              variant="Gray"
              alignment="Center"
              className={cx('tw-flex-1', !isHostValid && 'tw-outline-error')}
              value={item.connectionOptions.host}
              onChange={(e) => {
                const host = e.target.value;

                setIsFieldValid('host')(isValidHostname(host));

                onUpdate({
                  ...item,
                  connectionOptions: {
                    ...item.connectionOptions,
                    host,
                  },
                });
              }}
            />
          </SettingsGroupItem>

          <SettingsGroupItem>
            <div className={cx('tw-flex-1')}>Port</div>
            <NumberInput
              placeholder="port"
              variant="Gray"
              alignment="Center"
              className="tw-flex-1"
              value={item.connectionOptions.port ?? ONROBOT_TCP_PORT_DEFAULT}
              onChange={(value) =>
                onUpdate({
                  ...item,
                  connectionOptions: {
                    ...item.connectionOptions,
                    port: value,
                  },
                })
              }
            />
          </SettingsGroupItem>

          {/* connection timeout displayed in seconds */}
          <SettingsGroupItem>
            <div className={cx('tw-flex-1')}>Timeout</div>
            <NumberInput
              className="tw-w-[120px]"
              alignment="Center"
              variant="Gray"
              step={1}
              min={0}
              value={item.connectionOptions.timeoutMS / 1_000}
              onChange={(value) =>
                onUpdate({
                  ...item,
                  connectionOptions: {
                    ...item.connectionOptions,
                    timeoutMS: value * 1_000,
                  },
                })
              }
            />
            <span className="tw-text-label-secondary tw-w-52 tw-text-right">
              sec
            </span>
          </SettingsGroupItem>
        </SettingsGroup>

        {!isHostValid && (
          <p
            className={cx('tw-pt-12', 'tw-px-16', 'tw-text-13', 'tw-text-red')}
          >
            Hostname not valid
          </p>
        )}
      </div>

      <div className="tw-flex tw-flex-col">
        <h5 className="tw-heading-40 tw-px-16 tw-overflow-visible">
          Fields ({item.registers?.length ?? 0})
        </h5>

        <ModbusTCPServerRegisterFieldList
          registerFieldList={registerList}
          removeRegisterFieldRow={removeRegisterFieldRow}
          onChange={onRegisterFieldChange}
          isFormDisabled={isFormDisabled}
          setIsFieldValid={setIsFieldValid}
        />

        <Button
          size={44}
          variant="Elevated"
          className={cx('tw-rounded-10')}
          onClick={() => addRegisterFieldRow()}
        >
          <Icon kind="plus" />
          <span>Add field</span>
        </Button>
      </div>
    </>
  );
}
