import Image from 'next/image';

import type { OnRobot3FG15State } from '@sb/integrations/implementations/OnRobot3FG15/types';
import { useGripperControlState } from '@sb/integrations/implementations/OnRobot3FG15/widget/gripper-control-state';
import type { Integrations } from '@sb/remote-control/types';
import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';

interface DualOnRobot3FG15HomeWidgetInnerProps {
  routineRunnerPayload: number;
  routineRunnerGripperState: OnRobot3FG15State;
  args: Integrations.HomeWidgetProps;
  summaryStatus: Integrations.SummaryStatus;
}

export function DualOnRobot3FG15HomeWidgetInner({
  routineRunnerPayload,
  routineRunnerGripperState,
  args,
  summaryStatus,
}: DualOnRobot3FG15HomeWidgetInnerProps) {
  const { implementation } = args;

  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const gripperControlState = useGripperControlState({
    isVizbot: false,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const { currentDiameter } = gripperControlState;

  const getGripperStatus = () => {
    if (summaryStatus === 'busy' || summaryStatus === 'idle') {
      return <p className="tw-text-blue-30">Connected</p>;
    }

    if (
      summaryStatus === 'disconnected' ||
      (summaryStatus && summaryStatus.kind === 'error')
    ) {
      return <p className="tw-text-red-30">Not connected</p>;
    }

    return null;
  };

  const pathDualGripper = `/images/grippers/${implementation.imageSrc}`;
  const path = `/images/grippers/${routineRunnerGripperState.kind}.png`;

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-ellipsis tw-text-blue tw-max-w-[186px]">
            OnRobot 3FG15
          </h3>
          <h3 className="tw-text-blue">Dual Quick Changer</h3>

          {!!currentDiameter && (
            <p>{Math.round(currentDiameter * 1000)}mm diameter</p>
          )}

          <p>{currentPayload}kg payload</p>
        </div>
        <div className="tw-mb-8">{getGripperStatus()}</div>
      </div>
      <div className="tw-relative tw-w-[132px] tw-h-[132px]">
        <Image
          src={path}
          alt={routineRunnerGripperState.kind}
          width="132"
          height="132"
        />
        <Image
          src={pathDualGripper}
          alt={implementation.getDisplayName()}
          width="72"
          height="72"
          className="tw-absolute tw-z-10 tw-bottom-0 tw-right-0"
        />
      </div>
    </>
  );
}
