import Image from 'next/image';

import type { Integrations } from '@sb/remote-control/types';
import { PAYLOAD_MASS_KG_DEFAULT } from '@sb/routine-runner';

import type { OnRobot2FG7State } from '../types';

import { useGripperControlState } from './gripper-control-state';

interface OnRobot2FG7HomeWidgetInnerProps {
  routineRunnerPayload: number;
  routineRunnerGripperState: OnRobot2FG7State;
  args: Integrations.HomeWidgetProps;
}

export function OnRobot2FG7HomeWidgetInner({
  routineRunnerPayload,
  routineRunnerGripperState,
  args,
}: OnRobot2FG7HomeWidgetInnerProps) {
  const { implementation } = args;

  const currentPayload = routineRunnerPayload ?? PAYLOAD_MASS_KG_DEFAULT;

  const gripperControlState = useGripperControlState({
    isVizbot: false,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const { currentGripWidth } = gripperControlState;

  const summaryStatus =
    implementation.getSummaryStatus &&
    implementation.getSummaryStatus(routineRunnerGripperState);

  const getGripperStatus = () => {
    if (summaryStatus === 'busy' || summaryStatus === 'idle') {
      return <p className="tw-text-blue-30">Connected</p>;
    }

    if (
      summaryStatus === 'disconnected' ||
      (summaryStatus && summaryStatus.kind === 'error')
    ) {
      return <p className="tw-text-red-30">Not connected</p>;
    }

    return null;
  };

  const path = `/images/grippers/${implementation.imageSrc}`;

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-ellipsis tw-text-blue tw-max-w-[186px]">
            {implementation.getDisplayName()}
          </h3>

          {!!currentGripWidth && (
            <p>{Math.round(currentGripWidth * 1000)}mm width</p>
          )}

          <p>{currentPayload}kg payload</p>
        </div>
        <div className="tw-mb-8">{getGripperStatus()}</div>
      </div>
      <Image
        src={path}
        alt={implementation.getManufacturerName()}
        width="132"
        height="132"
      />
    </>
  );
}
