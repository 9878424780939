import { GaugeStepperSingleValue } from '@sb/design-system';
import {
  SCREW_LENGTH_RANGE,
  screwLengthStringConvert,
} from '@sb/integrations/implementations/OnRobotScrewdriver';

import type { DualValueModeProps } from './types';

export default function GripperScrewLength({
  gripperControlState,
}: DualValueModeProps) {
  const { changeScrewLength, target } = gripperControlState;

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-flex tw-pl-16">Screw length</h5>

      <GaugeStepperSingleValue
        value={target.targetScrewLength}
        onChange={changeScrewLength}
        {...SCREW_LENGTH_RANGE}
        valueToString={screwLengthStringConvert}
      />
    </section>
  );
}
