import { useEffect } from 'react';

import { NavigationBar } from '@sb/design-system';
import type { OnRobot2FG7State } from '@sb/integrations/implementations/OnRobot2FG7/types';
import { GripperControlDualValueMode } from '@sb/integrations/implementations/OnRobot2FG7/widget/dual-value-mode/GripperControlDualValueMode';
import { GripperControlDualValueModeMoreMenu } from '@sb/integrations/implementations/OnRobot2FG7/widget/dual-value-mode/GripperControlDualValueModeMoreMenu';
import { useGripperControlState } from '@sb/integrations/implementations/OnRobot2FG7/widget/gripper-control-state';
import type { DeviceCommand } from '@sb/integrations/types';
import type { Integrations } from '@sb/remote-control/types';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import type {
  OnRobotDualQuickChangerCompatibleCommand,
  OnRobotDualQuickChangerCommand,
} from '../types';

interface DualOnRobot2FG7WidgetInnerProps {
  isVizbot: boolean;
  onCommandChange?: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  additionalControls?: React.ReactNode;
  routineRunnerGripperState: OnRobot2FG7State;
  otherRobotGripperState: OnRobot2FG7State | null;
  routineRunnerPayload: number;
  dualChangerCommand: OnRobotDualQuickChangerCommand;
  changeActiveGripperCommand: (
    action: OnRobotDualQuickChangerCompatibleCommand,
  ) => void;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function DualOnRobot2FG7WidgetInner({
  dualChangerCommand,
  changeActiveGripperCommand,
  routineRunnerGripperState,
  isVizbot,
  onCommandChange,
  otherRobotGripperState,
  routineRunnerPayload,
  additionalControls,
  getEquipmentByKind,
}: DualOnRobot2FG7WidgetInnerProps) {
  const gripperControlState = useGripperControlState({
    isVizbot,
    routineRunnerGripperState,
    routineRunnerPayload,
  });

  const { command } = gripperControlState;

  useEffect(() => {
    if (onCommandChange) {
      onCommandChange(dualChangerCommand);
    }
  }, [onCommandChange, dualChangerCommand]);

  useEffect(() => {
    changeActiveGripperCommand(command);
  }, [changeActiveGripperCommand, command]);

  const onRobot2FG7Implementation = getEquipmentByKind('OnRobot2FG7');

  return (
    <WidgetView data-testid="onrobot-2fg7-control-widget">
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <GripperControlDualValueModeMoreMenu
            gripperControlState={gripperControlState}
            isVizbot={isVizbot}
            otherRobotGripperState={otherRobotGripperState}
          />
        }
      >
        {onRobot2FG7Implementation.getDisplayName()}
      </NavigationBar>

      {additionalControls}

      <GripperControlDualValueMode
        gripperControlState={gripperControlState}
        isVizbot={isVizbot}
        dualChangerCommand={dualChangerCommand}
      />
    </WidgetView>
  );
}
