import { useId } from 'react';

import {
  NumberInput,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import type { DHKind } from '../types/DHUnionTypes';

export const makeEquipmentManagerForm = <T extends DHKind>(kind: T) =>
  function DHBaseEquipmentManagerForm(
    props: Integrations.EquipmentManagerEditFormProps,
  ) {
    const { isFormDisabled, item, onUpdate } = props;
    const minInputId = useId();
    const maxInputId = useId();

    if (item.kind !== kind) {
      throw new Error(`Invalid item kind: ${item.kind}`);
    }

    return (
      <div>
        <fieldset className="tw-flex tw-flex-col" disabled={isFormDisabled}>
          <legend className="tw-heading-40 tw-pl-16">Connection options</legend>
          <SettingsGroup>
            <SettingsGroupItem>
              <label htmlFor={minInputId} className="tw-flex-1">
                Minimum grip width
              </label>
              <NumberInput
                id={minInputId}
                size={32}
                variant="Gray"
                className="tw-w-128"
                alignment="Center"
                value={Math.round(item.minGripWidth * 1000)}
                decimalPlaces={0}
                onChange={(minGripWidth: number) => {
                  onUpdate({
                    ...item,
                    minGripWidth: minGripWidth / 1000,
                  });
                }}
              />
              <span className="tw-w-52 tw-text-right">mm</span>
            </SettingsGroupItem>
            <SettingsGroupItem>
              <label htmlFor={maxInputId} className="tw-flex-1">
                Maxiumum grip width
              </label>
              <NumberInput
                id={maxInputId}
                size={32}
                variant="Gray"
                className="tw-w-128"
                alignment="Center"
                value={Math.round(item.maxGripWidth * 1000)}
                decimalPlaces={0}
                onChange={(maxGripWidth: number) => {
                  onUpdate({
                    ...item,
                    maxGripWidth: maxGripWidth / 1000,
                  });
                }}
              />
              <span className="tw-w-52 tw-text-right">mm</span>
            </SettingsGroupItem>
          </SettingsGroup>
        </fieldset>
      </div>
    );
  };
