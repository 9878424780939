import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import { Menu, MenuItem, MenuSeparator, Popper } from '@sb/design-system';
import { getEquipmentByKind } from '@sb/integrations/loaders/getEquipmentByKind';
import type { Robot } from '@sb/remote-control/types';
import type { FirmwareBuildData, RobotBadgeStatus } from '@sbrc/hooks';
import {
  useFeatureFlag,
  useRobotGripperState,
  useRobotUnbrake,
} from '@sbrc/hooks';
import { LINK_TO_SCENE_PAGE, getLinkToRoutinePage } from '@sbrc/utils';

import { EquipmentManagerModal } from '../equipment-manager';
import { IOManagerModal } from '../io-manager';
import SafetySettingsModal from '../safety-settings/SafetySettingsModal';
import type { SoftwareBuildData } from '../software-update';

import { DisconnectedCard } from './DisconnectedCard';
import RobotMenuSettings from './RobotMenuSettings';
import { SpeedCard } from './SpeedCard';

export type RobotDropdownModalKind =
  | 'io'
  | 'move'
  | 'pin'
  | 'userSettings'
  | 'renameRobot'
  | 'safetySettings'
  | 'dataBackups'
  | 'software'
  | 'developerAPI'
  | 'equipmentManager'
  | 'factoryReset'
  | 'externalControl'
  | null;

interface RobotBadgeMenuProps {
  robot: Robot.ConvertedResponse;
  isVizbot?: boolean;
  robotBadgeStatus: RobotBadgeStatus;
  buildData: SoftwareBuildData;
  firmwareBuildData: FirmwareBuildData;
}

export default function RobotBadgeMenu({
  robot,
  isVizbot,
  robotBadgeStatus,
  buildData,
  firmwareBuildData,
}: RobotBadgeMenuProps) {
  const { push } = useRouter();

  const enableFirmwareMismatch = useFeatureFlag('enableFirmwareMismatch');

  const { shouldIndicateFirmwareExcludingIOMismatch } = firmwareBuildData;

  const { brakeRobot, canBrake } = useRobotUnbrake({ isVizbot });

  const [modal, setModal] = useState<RobotDropdownModalKind>(null);

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  const gripperState = useRobotGripperState({});

  const hasEquipmentError = useMemo(() => {
    if (gripperState) {
      const integration = getEquipmentByKind(gripperState.kind);
      const summaryStatus = integration.getSummaryStatus?.(gripperState);

      return (
        summaryStatus === 'disconnected' || summaryStatus?.kind === 'error'
      );
    }

    return false;
  }, [gripperState]);

  const closeModal = () => setModal(null);

  const { latestRoutineID } = robot;

  const isFirmwareMismatched =
    enableFirmwareMismatch &&
    shouldIndicateFirmwareExcludingIOMismatch &&
    !buildData.isSoftwareUpdateAvailable;

  const softwareUpdateIndicator = (() => {
    if (buildData.isSoftwareUpdateAvailable) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-blue tw-mr-8" />
      );
    }

    if (isFirmwareMismatched) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-red tw-mr-8" />
      );
    }

    return null;
  })();

  return (
    <>
      <Menu className="tw-w-256">
        {robotBadgeStatus.kind === 'disconnected' && <DisconnectedCard />}

        {robotBadgeStatus.kind !== 'disconnected' && (
          <SpeedCard robot={robot} />
        )}

        <MenuItem
          onClick={() => setModal('equipmentManager')}
          iconKind="squareGrid2x2"
          secondaryIconKind={
            hasEquipmentError ? 'exclamationTriangle' : undefined
          }
          className="[&>[data-icon-kind=exclamationTriangle]]:tw-text-red"
        >
          Equipment
        </MenuItem>

        <MenuItem
          onClick={() => setModal('io')}
          iconKind="powerplug"
          disabled={!robotBadgeStatus.isConnected}
        >
          I/O manager
        </MenuItem>

        <MenuSeparator />

        <MenuItem
          disabled={!latestRoutineID}
          onClick={() =>
            latestRoutineID && push(getLinkToRoutinePage(latestRoutineID))
          }
          iconKind="pencil"
        >
          Edit routine
        </MenuItem>

        <MenuItem
          disabled={!latestRoutineID}
          onClick={() => push(LINK_TO_SCENE_PAGE)}
          iconKind="play"
        >
          Play routine in visualizer
        </MenuItem>

        <MenuSeparator />

        <Popper
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          placement="right-end"
          offset={-220}
          skidding={80}
          content={<RobotMenuSettings robot={robot} />}
        >
          <MenuItem
            iconKind="settings"
            onClick={(e) => {
              setIsSettingsOpen(true);
              e.stopPropagation();
            }}
          >
            {softwareUpdateIndicator}
            Settings
          </MenuItem>
        </Popper>

        <MenuItem
          disabled={!canBrake(robotBadgeStatus)}
          onClick={brakeRobot}
          className={isVizbot ? 'tw-text-blue' : 'tw-text-red'}
          iconKind="exclamationBrake"
        >
          {isVizbot ? 'Brake visualizer robot' : 'Brake robot'}
        </MenuItem>
      </Menu>

      {modal === 'io' && (
        <IOManagerModal
          isOpen={modal === 'io'}
          onClose={closeModal}
          onOpenSafetySettings={() => setModal('safetySettings')}
          robot={robot}
        />
      )}

      <EquipmentManagerModal
        isOpen={modal === 'equipmentManager'}
        onClose={closeModal}
        robot={robot}
      />

      <SafetySettingsModal
        isOpen={modal === 'safetySettings'}
        onClose={closeModal}
        robot={robot}
      />
    </>
  );
}
