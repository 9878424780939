import { shallow } from 'zustand/shallow';

import {
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroupSelectItem,
} from '@sb/design-system';

import { useExternalControlModalStore } from './store';

export function ExternalControlDefaultRoutine() {
  const [setCurrentView, setField, defaultRoutineID, allRoutines] =
    useExternalControlModalStore(
      (s) => [
        s.setCurrentView,
        s.setField,
        s.settings.defaultRoutineID,
        s.allRoutines,
      ],
      shallow,
    );

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarBackButton onClick={() => setCurrentView('summary')}>
            Back
          </NavigationBarBackButton>
        }
      >
        External control
      </NavigationBar>

      <div className="tw-p-24 tw-pt-12 tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-32">
        <p className="tw-text-13 tw-text-label-tertiary tw-text-center">
          If set, the default routine will be loaded at startup and every time
          the robot is switched into External Control mode.
        </p>

        <SettingsGroupSelectItem
          isSeparated
          label="Default routine"
          value={defaultRoutineID ?? 'NONE'}
          onChange={(e) =>
            setField(
              'defaultRoutineID',
              e.target.value === 'NONE' ? null : e.target.value,
            )
          }
        >
          <option value="NONE">None</option>

          {defaultRoutineID && !allRoutines[defaultRoutineID] && (
            <option value={defaultRoutineID}>Deleted</option>
          )}

          {Object.entries(allRoutines).map(([routineID, routineName]) => (
            <option key={routineID} value={routineID}>
              {routineName}
            </option>
          ))}
        </SettingsGroupSelectItem>

        <p className="tw-text-13 tw-text-label-tertiary tw-text-center">
          The routine will not automatically run and must be started via an
          additional trigger defined elsewhere in External Control.
        </p>
      </div>
    </>
  );
}
