import { useEffect, useRef } from 'react';

import { Robot, type Integrations } from '@sb/remote-control/types';

import { useCustomIOEquipmentManagerContext } from './CustomIOEquipmentManagerContext';

interface UsePortDropdownDataArgs {
  isOpen: boolean;
  selectedPort?: Integrations.IOPort;
}

interface PortDropdownData {
  port: Integrations.IOPort;
  isDisabled: boolean;
  label?: string;
}

export function usePortDropdownData({
  isOpen,
  selectedPort,
}: UsePortDropdownDataArgs): PortDropdownData[] {
  const {
    portKind,
    assignedPorts,
    robotIOInputs,
    robotIOOutputs,
    otherEquipment,
  } = useCustomIOEquipmentManagerContext();

  const portDropdownDataRef = useRef<PortDropdownData[]>([]);

  const portDropdownData = (() => {
    // only update this while open
    if (!isOpen) {
      return portDropdownDataRef.current;
    }

    const ioPorts = portKind === 'Input' ? robotIOInputs : robotIOOutputs;

    return ioPorts.map((port) => {
      if (port.kind === selectedPort?.kind && port.port === selectedPort.port) {
        return {
          port,
          isDisabled: false,
          label: 'Selected',
        };
      }

      if (
        assignedPorts.some((p) => p.kind === portKind && p.port === port.port)
      ) {
        return {
          port,
          isDisabled: true,
          label: 'Assigned',
        };
      }

      if (
        port.assignedTo === Robot.IO_PORT_ASSIGN_TO_SAFETY_IO ||
        port.assignedTo === Robot.IO_PORT_ASSIGN_TO_EXTERNAL_CONTROL
      ) {
        return {
          port,
          isDisabled: true,
          label: `Assigned to: ${port.assignedTo}`,
        };
      }

      const assignedToDevice = otherEquipment.find(
        (e) =>
          e.config.kind === 'CustomIO' &&
          e.config.ports.some(
            (p) => p.kind === portKind && p.port === port.port,
          ),
      );

      if (assignedToDevice) {
        return {
          port,
          isDisabled: true,
          label: `Assigned to: ${assignedToDevice.config.name}`,
        };
      }

      return {
        port,
        isDisabled: false,
      };
    });
  })();

  // keep this in a ref to use when not isOpen
  useEffect(() => {
    portDropdownDataRef.current = portDropdownData;
  }, [portDropdownData]);

  return portDropdownData;
}
