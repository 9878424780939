import { Icon } from '@sb/design-system';

interface SpeedBadgeProps {
  speedRestrictionPercentage: number;
  isSlowSpeed: boolean;
  showSpeed: boolean;
  hidden?: boolean;
}

export function SpeedBadge({
  hidden,
  speedRestrictionPercentage,
  isSlowSpeed,
  showSpeed,
}: SpeedBadgeProps) {
  if (hidden) {
    return null;
  }

  const isRestricted = speedRestrictionPercentage < 1;

  if (!isSlowSpeed && !isRestricted) {
    return null;
  }

  return (
    <>
      <span className="tw-text-label-tertiary tw-ml-2">
        {isSlowSpeed && 'Slow '}
        {isRestricted && `${Math.round(speedRestrictionPercentage * 100)}%`}
      </span>
      {showSpeed && (
        <Icon
          className="tw-text-label-tertiary"
          kind={isSlowSpeed ? 'speedometerSlow' : 'speedometerFast'}
        />
      )}
    </>
  );
}
