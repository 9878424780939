import type { Robot, Routine } from '@sb/remote-control/types';
import { useRobotStateKind } from '@sbrc/hooks';

import { PlayPausePlayer } from './PlayPausePlayer';

interface PlayPauseButtonProps {
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
  isRoutinePage?: boolean;
  isPreflightChecksPage?: boolean;
}

export function PlayPauseButton({
  robot,
  routine,
  isRoutinePage,
  isPreflightChecksPage,
}: PlayPauseButtonProps) {
  const routineRunnerKind = useRobotStateKind({});

  const routineID = routine ? routine.id : null;

  const routineStatus = isPreflightChecksPage ? 'Preparing to run' : 'Loaded';

  if (isRoutinePage) {
    return null;
  }

  if (!isRoutinePage && !routineID) {
    return (
      <div className="tw-flex tw-items-center tw-gap-8">
        <div className="tw-text-label-tertiary">No routine loaded</div>
      </div>
    );
  }

  if (!isRoutinePage && routineID && routineRunnerKind !== 'RoutineRunning') {
    return (
      <div className="tw-flex tw-flex-col tw-text-ellipsis tw-min-w-[160px] tw-max-w-256">
        <div className="first-letter:tw-uppercase">{routine?.name}</div>
        <p className="tw-text-13 tw-text-label-secondary">{routineStatus}</p>
      </div>
    );
  }

  return (
    <PlayPausePlayer
      robot={robot}
      routineName={routine?.name || ''}
      className="tw-w-[288px]"
    />
  );
}
