import type { Integrations } from '@sb/remote-control/types';

import { getDHCommon } from '../../comon/frontend';
import { makeEquipmentManagerForm } from '../../comon/ui/EquipmentManager';
import { makeHomeWidget } from '../../comon/ui/HomeWidget';
import { makeWidget } from '../../comon/ui/Widget';

import { DefaultConfiguration } from './Configuration';

export const dhAG105145Frontend: Integrations.IntegrationFrontend<'DHAG105145'> =
  {
    ...getDHCommon(),
    imageSrc: 'DHAG105145.png',
    Widget: makeWidget('DHAG105145'),
    HomeWidget: makeHomeWidget('DHAG105145'),
    EquipmentManagerEditForm: makeEquipmentManagerForm('DHAG105145'),
    getDisplayName: () => 'DH AG 105/145',
    getShortName: () => 'DHA105145',
    getDefaultItem: () => ({ ...DefaultConfiguration }),
  };
