import type { DeviceKind } from '@sb/integrations/types';

import { DeviceConfigurationRegistry } from '../registry/deviceConfiguration';

const list = (() => {
  const listOfKinds: DeviceKind[] = [];

  for (const device of DeviceConfigurationRegistry) {
    // backdoor way to get the value of the 'kind' literal
    // eslint-disable-next-line no-underscore-dangle
    const kind = device.shape.kind._def.value;

    if (!['NoGripper', 'InvalidConfiguration'].includes(kind)) {
      listOfKinds.push(kind);
    }
  }

  return listOfKinds;
})();

export const getEquipmentList = (): DeviceKind[] => list;
