import { GaugeStepperSingleValue } from '@sb/design-system';
import {
  COMMAND_TORQUE_NM_DEFAULT,
  TORQUE_RANGE,
  torqueStringConvert,
} from '@sb/integrations/implementations/OnRobotScrewdriver';
import { useIsRobotMoving, useRobotGripperState } from '@sbrc/hooks';

import type { DualValueModeProps } from './types';

export default function GripperTargetTorque({
  gripperControlState,
  isVizbot,
}: DualValueModeProps) {
  const { changeTargetTorque, target } = gripperControlState;

  const { isRobotMoving } = useIsRobotMoving({ isVizbot });

  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot },
    'OnRobotScrewdriver',
  );

  const achievedTorqueString =
    routineRunnerGripperState?.achievedTorque.toFixed(3);

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-flex tw-px-16 tw-justify-between">
        <span>Target torque</span>
        {!isRobotMoving && achievedTorqueString && (
          <span className="tw-font-regular tw-text-13 tw-ml-auto">
            Achieved: {achievedTorqueString}NM
          </span>
        )}
      </h5>

      <GaugeStepperSingleValue
        value={target.targetTorque ?? COMMAND_TORQUE_NM_DEFAULT}
        onChange={changeTargetTorque}
        {...TORQUE_RANGE}
        valueToString={torqueStringConvert}
      />
    </section>
  );
}
