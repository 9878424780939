import cx from 'classnames';
import isValidHostname from 'is-valid-hostname';
import { useEffect, useId } from 'react';

import {
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSelectItem,
  TextInput,
} from '@sb/design-system';
import type {
  OnRobotConnectionConfiguration,
  OnRobotConnectionKind,
} from '@sb/integrations/implementations/gripper-general/types/Configuration';

interface OnRobotConnectionOptionsProps {
  value: OnRobotConnectionConfiguration;
  onChange: (value: OnRobotConnectionConfiguration) => void;
  setIsValid: (isValid: boolean) => void;
  isDisabled?: boolean;
  isChildDevice?: boolean;
}

export function OnRobotConnectionOptions({
  value,
  onChange,
  setIsValid,
  isDisabled,
  isChildDevice,
}: OnRobotConnectionOptionsProps) {
  const inputID = useId();

  const isHostValid =
    value.kind !== 'modbusTCP' || isValidHostname(value.modbusTCP.host);

  useEffect(() => {
    setIsValid(isHostValid);
  }, [setIsValid, isHostValid]);

  // TODO: SYS-1251: Support OnRobot ModbusTCP Connection for Dual Changer child devices
  if (isChildDevice) {
    return null;
  }

  return (
    <fieldset className="tw-flex tw-flex-col" disabled={isDisabled}>
      <legend className="tw-heading-40 tw-pl-16">Connection options</legend>

      <SettingsGroup>
        <SettingsGroupSelectItem
          label="Kind"
          value={value.kind}
          onChange={(e) => {
            const getConnectionKind = (): OnRobotConnectionKind => {
              switch (e.target.value) {
                case 'tooltipModbus':
                  return 'tooltipModbus';
                case 'modbusTCP':
                  return 'modbusTCP';
                default:
                  return 'tooltipModbus';
              }
            };

            onChange({
              ...value,
              kind: getConnectionKind(),
            });
          }}
        >
          <option value="tooltipModbus">Tooltip Modbus</option>
          <option value="modbusTCP">Modbus TCP</option>
        </SettingsGroupSelectItem>

        {value.kind === 'modbusTCP' && (
          <SettingsGroupItem>
            <label className="tw-flex-1" htmlFor={inputID}>
              Host IP
            </label>

            <TextInput
              id={inputID}
              className={cx('tw-w-320', !isHostValid && 'tw-outline-error')}
              size={32}
              variant="Gray"
              value={value.modbusTCP.host}
              onChange={(host) => {
                onChange({
                  ...value,
                  modbusTCP: {
                    ...value.modbusTCP,
                    host,
                  },
                });
              }}
            />
          </SettingsGroupItem>
        )}
      </SettingsGroup>

      {!isHostValid && (
        <p className="tw-text-red tw-text-13 tw-pt-8 tw-pl-16">
          Hostname not valid
        </p>
      )}
    </fieldset>
  );
}
