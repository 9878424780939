import { shallow } from 'zustand/shallow';

import { Modal } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';

import { ExternalControl24VIO } from './ExternalControl24VIO';
import { ExternalControlDefaultRoutine } from './ExternalControlDefaultRoutine';
import { ExternalControlSummary } from './ExternalControlSummary';
import {
  useExternalControlModalStore,
  useInitializeExternalControlModalStore,
} from './store';

interface ExternalControlModalProps {
  robot: Robot.ConvertedResponse;
}

export function ExternalControlModal({ robot }: ExternalControlModalProps) {
  const [currentView, isOpen, setIsOpen] = useExternalControlModalStore(
    (s) => [s.currentView, s.isOpen, s.setIsOpen],
    shallow,
  );

  useInitializeExternalControlModalStore({
    isOpen,
    robotID: robot.id,
    ioInputs: robot.ioInputs,
    ioOutputs: robot.ioOutputs,
  });

  return (
    <Modal
      className="tw-w-512"
      isOpen={isOpen}
      onClose={() => {
        if (currentView === 'summary') {
          setIsOpen(false);
        }
      }}
    >
      {currentView === 'summary' && (
        <ExternalControlSummary onClose={() => setIsOpen(false)} />
      )}
      {currentView === '24VIO' && <ExternalControl24VIO />}
      {currentView === 'defaultRoutine' && <ExternalControlDefaultRoutine />}
    </Modal>
  );
}
