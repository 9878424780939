import { useState } from 'react';

import {
  Button,
  Icon,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import type { CameraStreamSource } from '@sb/integrations/types/cameraTypes';
import type { Integrations } from '@sb/remote-control/types';
import { useToast } from '@sbrc/hooks';
import { uploadFileToStorage } from '@sbrc/services';

import { CameraStreamSourceItem } from './CameraStreamSourceItem';
import { IntrinsicsCalibration } from './IntrinsicsCalibration';
import { Timestamp } from './Timestamp';

export function WristCameraEquipmentManager({
  item,
  onUpdate,
  onSave,
  isFormDisabled,
  setChildContent,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'WristCamera') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const { setToast } = useToast();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [inputKey, setInputKey] = useState(Date.now()); // Initialize key with current timestamp

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    try {
      const { id } = await uploadFileToStorage({
        file: selectedFile,
      });

      const newStreamSource = {
        id,
        name: selectedFile.name,
      };

      const updatedSources = item.cameraStreamSources.filter(
        (s) => s.id !== newStreamSource.id,
      );

      onUpdate({
        ...item,
        cameraStreamSources: [...updatedSources, newStreamSource],
        selectedCameraStreamSource: newStreamSource,
      });

      setSelectedFile(null);
      setInputKey(Date.now());
    } catch (error) {
      setToast({ kind: 'error', message: 'Failed to upload file', error });
    }
  };

  const handleDeleteSource = (source: CameraStreamSource) => {
    const updatedSources = item.cameraStreamSources.filter(
      (s) => s.id !== source.id,
    );

    onUpdate({
      ...item,
      cameraStreamSources: updatedSources,
      selectedCameraStreamSource:
        source.id === item.selectedCameraStreamSource?.id
          ? null
          : item.selectedCameraStreamSource,
    });
  };

  const handleSourceSelect = (source: CameraStreamSource | null) => {
    onUpdate({
      ...item,
      selectedCameraStreamSource: source,
    });
  };

  return (
    <>
      <section>
        <h5 className="tw-heading-40 tw-pl-16">Calibration</h5>

        <SettingsGroup>
          <SettingsGroupNavigationItem
            label={
              <>
                <span>Intrinsics calibration</span>
                {item.intrinsics && (
                  <Timestamp
                    timestamp={item.intrinsicsCalibration.at(-1)?.timestamp}
                    dateOnly
                  />
                )}
                {!item.isIntrinsicsCalibrationEnabled && (
                  <span className="tw-text-label-tertiary tw-text-13">
                    (disabled)
                  </span>
                )}
              </>
            }
            disabled={isFormDisabled}
            onClick={async () => {
              await onSave();

              setChildContent(
                <IntrinsicsCalibration
                  initialConfiguration={item}
                  onBack={() => setChildContent(null)}
                  onUpdate={onUpdate}
                />,
              );
            }}
          >
            Calibrate
          </SettingsGroupNavigationItem>
        </SettingsGroup>
      </section>

      <section>
        <h5 className="tw-heading-40 tw-pl-16">Simulated Cameras</h5>

        <SettingsGroup>
          {item.cameraStreamSources.map((source) => (
            <CameraStreamSourceItem
              name={source.name}
              isSelected={item.selectedCameraStreamSource?.id === source.id}
              onSelect={() => handleSourceSelect(source)}
              onDelete={() => handleDeleteSource(source)}
            />
          ))}

          {/* "Camera" option */}
          <SettingsGroupItem onClick={() => handleSourceSelect(null)}>
            <Icon
              kind={
                item.selectedCameraStreamSource === null ? 'checkmark' : 'blank'
              }
            />
            <span className="tw-flex-1">Camera</span>
          </SettingsGroupItem>

          <SettingsGroupItem>
            <input
              key={inputKey}
              type="file"
              onChange={handleFileSelect}
              disabled={isFormDisabled}
            />
            {selectedFile && (
              <Button
                variant="Filled"
                size={32}
                className="tw-rounded-6"
                onClick={handleFileUpload}
              >
                Upload
              </Button>
            )}
          </SettingsGroupItem>
        </SettingsGroup>
      </section>
    </>
  );
}
