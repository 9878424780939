import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import {
  Button,
  Menu,
  MenuItem,
  RichSelect,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';
import type { DBBackupData, USBDriveData } from '@sb/feathers-types';
import { Loader } from '@sb/ui/components';
import { ModalContext } from '@sb/ui/components/Modal/shared';
import { createBackup } from '@sbrc/services/feathers-client';

import { DriveStatusIcon } from './DriveStatusIcon';

export const CreateBackupTab = ({
  usbData,
  robotID,
}: {
  usbData: USBDriveData[];
  robotID: string;
}) => {
  const { onClose } = useContext(ModalContext);
  const [backupData, setBackupData] = useState<DBBackupData | null>(null);

  const [driveData, setDriveData] = useState(
    usbData.length > 0 ? usbData[0] : null,
  );

  const [isBackingUp, setIsBackingUp] = useState<boolean>(false);

  useEffect(() => {
    const driveIndex = usbData.findIndex(
      (drive) => drive.label === driveData?.label,
    );

    if (!driveData || driveIndex === -1) {
      setDriveData(usbData.length > 0 ? usbData[0] : null);
    } else {
      setDriveData(usbData[driveIndex]);
    }
  }, [usbData, driveData, setDriveData]);

  const createBackupClick = async () => {
    if (!driveData?.label) {
      return;
    }

    setBackupData(null);
    setIsBackingUp(true);

    try {
      const createdBackupData = await createBackup(driveData?.label, robotID);
      setIsBackingUp(false);
      setBackupData(createdBackupData);
    } catch (err) {
      setIsBackingUp(false);

      setBackupData({
        path: driveData.label,
        robotID,
        key: '',
        date: new Date(),
        status: 'failed',
        error: err.message,
      });
    }
  };

  let driveHeader;
  let driveHeaderLabel;
  let subText;
  let backupPossible = false;

  if (driveData) {
    backupPossible = true;

    driveHeaderLabel = isBackingUp
      ? `Backing up to "${driveData.label}."`
      : driveData.label;

    if (backupData) {
      subText = backupData.error ?? backupData.status ?? 'creating backup';

      if (backupData.status === 'failed' || backupData.error) {
        driveHeaderLabel = `Error occurred writing to "${driveData.label}"`;

        subText =
          backupData.error ??
          'You can retry, try another drive, or contact support.';
      }

      if (backupData.status === 'success') {
        driveHeaderLabel = `Backup saved to "${driveData.label}."`;
        subText = 'You can restore it later via this window.';
      }
    } else
      subText =
        driveData.backups.length === 0
          ? 'No existing backups detected.'
          : `Last backup: ${dayjs(driveData.backups[0].date).format(
              'ddd, MMM D, YYYY HH:mm',
            )}`;
  } else {
    driveHeaderLabel = 'No disk inserted.';
  }

  if (usbData.length > 1 && !isBackingUp && !backupData) {
    driveHeader = (
      <RichSelect
        variant="Flat"
        className="tw-rounded-10"
        options={
          <Menu>
            {usbData.map((usb) => (
              <MenuItem
                key={usb.label}
                onClick={() => setDriveData(usb)}
                iconKind={
                  driveData?.label === usb.label ? 'checkmark' : 'blank'
                }
              >
                {usb.label}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        {driveData?.label ?? 'select drive...'}
      </RichSelect>
    );
  } else {
    driveHeader = (
      <p className="tw-text-15 tw-font-medium">{driveHeaderLabel}</p>
    );
  }

  return (
    <>
      <p className="tw-text-15">
        To create a backup of the routines on this robot, insert a thumbdrive or
        other USB storage device into the USB-A port on the bottom of the
        control box.
      </p>
      <div className="tw-flex tw-flex-col tw-gap-12 tw-mt-16">
        <SettingsGroup>
          <SettingsGroupItem>
            {driveData && (
              <div className="tw-flex-1">
                <DriveStatusIcon status={backupData?.status} />
              </div>
            )}
            {driveHeader}
          </SettingsGroupItem>
        </SettingsGroup>

        {subText && (
          <p className="tw-px-16 tw-text-15 tw-text-label-tertiary">
            {isBackingUp ? 'Creating backup...' : subText}
          </p>
        )}

        {isBackingUp && <Loader />}
        {!isBackingUp && !backupData && (
          <Button
            className="tw-rounded-10"
            variant="Filled"
            disabled={isBackingUp || !backupPossible}
            onClick={createBackupClick}
          >
            Create Backup
          </Button>
        )}
        {backupData?.status === 'success' && (
          <Button className="tw-rounded-10" onClick={onClose}>
            Done
          </Button>
        )}
        {!isBackingUp && backupData?.status === 'failed' && (
          <Button
            color="Red"
            className="tw-bg-red-10 dark:tw-bg-red-100 tw-rounded-10 enabled:hover:tw-brightness-90"
            onClick={createBackupClick}
          >
            Retry
          </Button>
        )}
      </div>
    </>
  );
};
