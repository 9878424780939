import cx from 'classnames';
import { useMemo } from 'react';

import {
  HoldableButton,
  GaugeStepperDualValue,
  NavigationBar,
} from '@sb/design-system';
import GripperErrorWidget from '@sb/integrations/implementations/gripper-general/shared-components/GripperError';
import type { Integrations } from '@sb/remote-control/types';
import { convertMetersToMillimeterString } from '@sb/utilities';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import {
  useIsAnotherSessionRunningAdHocCommand,
  useRobotStateKind,
} from '@sbrc/hooks';
import { useRobotGripperState } from '@sbrc/hooks/useRobotGripperState';

import type { DHKind } from '../types/DHUnionTypes';

import { useGripperControl } from './useGripperControl';

export const makeWidget = <T extends DHKind>(kind: T) =>
  function DHBaseWidget(props: Integrations.WidgetProps) {
    const { isVizbot, implementation } = props;
    const control = useGripperControl({ isVizbot, kind });

    const state = useRobotGripperState<DHKind>({ isVizbot }, kind);

    const isAnotherSessionMovingRobot = useIsAnotherSessionRunningAdHocCommand({
      isVizbot,
    });

    const isRoutineRunning =
      useRobotStateKind({ isVizbot }) === 'RoutineRunning';

    const gripsEqualish = useMemo(() => {
      if (!state?.width) {
        return false;
      }

      return Math.abs(state.width - control.targetWidth) < 0.0005;
    }, [state?.width, control.targetWidth]);

    if (!state || state.kind !== kind || !state.isConnected) {
      return (
        <GripperErrorWidget
          implementation={implementation}
          message={`The gripper does not appear to be connected. Please ensure a ${kind} gripper is properly mounted and connected.`}
        />
      );
    }

    const canApplyGripperChanges =
      !isRoutineRunning && !isAnotherSessionMovingRobot && !gripsEqualish;

    const isDisabled = !state.isConnected || Boolean(state.error);

    return (
      <WidgetView>
        <NavigationBar className="tw-pl-8 tw-pr-8">
          {implementation.getDisplayName()}
        </NavigationBar>
        <div
          className={cx(
            'tw-flex',
            'tw-flex-col',
            'tw-flex-1',
            'tw-overflow-auto',
            'tw-gap-16',
            'tw-px-16',
          )}
        >
          <section className="tw-flex tw-flex-col">
            <h5 className="tw-heading-40 tw-pl-16">Grip width</h5>

            <GaugeStepperDualValue
              kind="Filled"
              value={state.width}
              targetValue={gripsEqualish ? undefined : control.targetWidth}
              onChange={control.setTargetWidth}
              min={state.minWidth}
              max={state.maxWidth}
              step={0.001}
              valueToString={convertMetersToMillimeterString}
            />
          </section>
          <div className="tw-flex tw-flex-col">
            <HoldableButton
              className="tw-rounded-6 tw-m-16"
              variant="Filled"
              disabled={isDisabled || !canApplyGripperChanges}
              onHold={control.onHoldApplyChangesButton}
              onRelease={control.stopGuidedMode}
              data-testid="gripper-widget-apply-changes-button"
            >
              Hold to Apply Changes
            </HoldableButton>
          </div>
        </div>
      </WidgetView>
    );
  };
