import { GaugeStepperDualValue } from '@sb/design-system';
import { forceToString } from '@sb/utilities';

import {
  calculateTargetForceFromCommandKind,
  OR_3FG15_FORCE_NEWTONS_SLIDER_STEP,
} from '../..';

import type { DualValueModeProps } from './types';

export default function GripperForce({
  gripperControlState,
}: DualValueModeProps) {
  const {
    setTargetForce,
    command,
    forceRange,
    currentForce,
    isForceEqual,
    commandKind,
  } = gripperControlState;

  const targetForce = command.targetForce ?? NaN;

  const target3FG15Force = calculateTargetForceFromCommandKind(
    commandKind,
    targetForce,
    setTargetForce,
    null,
  );

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Force</h5>
      <GaugeStepperDualValue
        kind="Filled"
        value={currentForce}
        targetValue={isForceEqual ? undefined : target3FG15Force}
        onChange={setTargetForce}
        min={forceRange.min}
        max={forceRange.max}
        step={OR_3FG15_FORCE_NEWTONS_SLIDER_STEP}
        valueToString={forceToString}
        isDisabled={commandKind === 'move'}
      />
    </section>
  );
}
