import type { SafeguardPair } from '@sb/routine-runner';
import { SAFEGUARD_PAIR_PORTS, SafeguardKind } from '@sb/routine-runner';
import { Checkbox, Select, SelectItem, Typography } from '@sb/ui/components';
import { IOBadge } from '@sbrc/components/io-manager';
import { useRobotIOState } from '@sbrc/hooks';

import { useSafetySettingsStore, useSettingValue } from '../../store';

import { asSafeguardKind, SAFEGUARD_KIND_INFOS } from './safeguardKinds';

import styles from './SafeguardRuleSettings.module.css';

interface SafeguardStopOptionsProps {
  pair: SafeguardPair;
  fieldName: string;
}

export function SafeguardRuleSettings({
  pair,
  fieldName,
}: SafeguardStopOptionsProps) {
  const [safeguardKind, setSafeguardKind, , isSafegardActionDisabled] =
    useSettingValue(`${fieldName}.kind`, asSafeguardKind);

  const { ioInputs } = useSafetySettingsStore('ioInputs');

  const [isAutoReset, setIsAutoReset, , isAutoResetDisabled] = useSettingValue(
    `${fieldName}.isAutoReset`,
    Boolean,
  );

  const safeguardActionInfo = SAFEGUARD_KIND_INFOS[safeguardKind];

  const safeguardPairPorts = SAFEGUARD_PAIR_PORTS[pair];

  const port0State = useRobotIOState({
    kind: 'Input',
    port: safeguardPairPorts[0],
  });

  const port1State = useRobotIOState({
    kind: 'Input',
    port: safeguardPairPorts[1],
  });

  const portValue = (() => {
    if (port0State === null || port1State === null) {
      return <Typography color="warning">Unknown</Typography>;
    }

    const port0StateName = port0State === 'high' ? 'High' : 'Low';
    const port1StateName = port1State === 'high' ? 'High' : 'Low';

    if (port0StateName === port1StateName) {
      return <Typography>{port0StateName}</Typography>;
    }

    return (
      <Typography color="alertPrimary">
        <span className={styles.warningIcon}>!</span>
        <strong>Mismatch</strong> ({port0StateName}, {port1StateName})
      </Typography>
    );
  })();

  const getCustomIODevice = (port: number) => {
    const ioInput = ioInputs.find((p) => p.port === port);

    return ioInput?.assignedTo && !ioInput.safeguardKind
      ? ioInput.assignedTo
      : undefined;
  };

  const portUsedMessage = (() => {
    const device1 = getCustomIODevice(safeguardPairPorts[0]);
    const device2 = getCustomIODevice(safeguardPairPorts[1]);

    if (device1 && device2 && device1 !== device2) {
      return `Assigned to: ${device1} and ${device2}`;
    }

    if (device1) {
      return `Assigned to: ${device1}`;
    }

    if (device2) {
      return `Assigned to: ${device2}`;
    }

    return null;
  })();

  return (
    <>
      <div className={styles.badges}>
        <IOBadge>In {safeguardPairPorts[0]}</IOBadge>
        <span>+</span>
        <IOBadge>In {safeguardPairPorts[1]}</IOBadge>
      </div>

      {portValue}

      {portUsedMessage ? (
        <Typography color="gray" className={styles.portUsedMessage} hasNoWrap>
          {portUsedMessage}
        </Typography>
      ) : (
        <>
          <Select
            className={styles.actionSelect}
            dropdownClassName={styles.actionDropdown}
            isDisabled={isSafegardActionDisabled}
            activeLabel={safeguardActionInfo.description}
            labelPosition="left"
            sizeVariant="small"
          >
            {SafeguardKind.options.map((kind) => (
              <SelectItem
                key={kind}
                onClick={() => {
                  setSafeguardKind(kind);

                  if (!SAFEGUARD_KIND_INFOS[kind].supportsAutoReset) {
                    setIsAutoReset(false);
                  }
                }}
              >
                {SAFEGUARD_KIND_INFOS[kind].description}
              </SelectItem>
            ))}
          </Select>
          <Checkbox
            className={styles.autoResetCheckbox}
            checked={isAutoReset}
            onChange={(e) => setIsAutoReset(e.target.checked)}
            disabled={
              isAutoResetDisabled || !safeguardActionInfo.supportsAutoReset
            }
          >
            Auto-Reset
          </Checkbox>
        </>
      )}
    </>
  );
}
