import { useRouter } from 'next/router';
import { useState } from 'react';

import { Menu, MenuItem, MenuSeparator } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  useClearMotionPlanCache,
  useFeatureFlag,
  useFirmwareUpdateStatus,
  useIsRobotMoving,
  useRobotBadgeStatus,
  useSoftwareUpdateData,
} from '@sbrc/hooks';
import { LINK_TO_BOX_PAGE, LINK_TO_LOGOUT_PAGE } from '@sbrc/utils';

import UpdatePINModal from '../auth/UpdatePINModal';
import { DataBackupModal } from '../data-backup/DataBackupModal';
import { DeveloperAPIModal } from '../developer-api/DeveloperAPIModal';
import { ExternalControlMenuItem } from '../external-control/ExternalControlMenuItem';
import { FactoryResetModal } from '../factory-reset/FactoryResetModal';
import RobotFormModal from '../robot-form/RobotFormModal';
import SafetySettingsModal from '../safety-settings/SafetySettingsModal';
import { SoftwareUpdateModal, useSoftwareBuildData } from '../software-update';
import { UserSettingsModal } from '../user-settings/UserSettingsModal';

import type { RobotDropdownModalKind } from './RobotBadgeMenu';

interface RobotMenuSettingsProps {
  robot: Robot.ConvertedResponse;
}

export default function RobotMenuSettings({ robot }: RobotMenuSettingsProps) {
  const { push } = useRouter();
  const enableDeveloperAPI = useFeatureFlag('enableDeveloperAPI');

  const softwareUpdateDataPoll1Second = useSoftwareUpdateData(1_000);

  const firmwareBuildData = useFirmwareUpdateStatus(
    softwareUpdateDataPoll1Second,
  );

  // build data
  const buildData = useSoftwareBuildData({
    shouldReloadOnActiveBuildIdChange: true,
  });

  const robotBadgeStatus = useRobotBadgeStatus();

  const clearMotionPlanCache = useClearMotionPlanCache();

  const { shouldIndicateFirmwareExcludingIOMismatch } = firmwareBuildData;

  const [modal, setModal] = useState<RobotDropdownModalKind>(null);

  const { isRobotMoving } = useIsRobotMoving({});

  const closeModal = () => setModal(null);

  const softwareUpdateIndicator = (() => {
    if (buildData.isSoftwareUpdateAvailable) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-blue tw-mr-8" />
      );
    }

    if (
      shouldIndicateFirmwareExcludingIOMismatch &&
      !buildData.isSoftwareUpdateAvailable
    ) {
      return (
        <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-red tw-mr-8" />
      );
    }

    return null;
  })();

  return (
    <>
      <Menu className="tw-w-256 tw-surface-secondary">
        <MenuItem onClick={() => setModal('safetySettings')} iconKind="shield">
          Safety
        </MenuItem>

        <MenuItem
          onClick={() => setModal('dataBackups')}
          iconKind="clockArrowCirclePath"
        >
          Backups
        </MenuItem>

        <MenuItem
          onClick={() => setModal('software')}
          iconKind="arrowDownSquare"
          disabled={isRobotMoving}
        >
          {softwareUpdateIndicator}
          Software update
        </MenuItem>

        <MenuSeparator />

        <MenuItem
          onClick={() => setModal('userSettings')}
          iconKind="sliderHorizontal"
        >
          User settings
        </MenuItem>

        <ExternalControlMenuItem />

        <MenuItem onClick={() => setModal('renameRobot')} iconKind="pencil">
          Robot name
        </MenuItem>

        <MenuItem onClick={() => setModal('pin')} iconKind="circleGrid3x3">
          Update passcode
        </MenuItem>

        <MenuSeparator />

        <MenuItem
          onClick={() => push(LINK_TO_BOX_PAGE)}
          iconKind="shippingBox"
          disabled={!robotBadgeStatus.isConnected}
        >
          Box robot
        </MenuItem>

        <MenuItem
          onClick={() => setModal('factoryReset')}
          iconKind="reset"
          disabled={buildData.isInstallingSoftware}
        >
          Factory Reset
        </MenuItem>

        <MenuItem
          onClick={() => clearMotionPlanCache()}
          iconKind="reset"
          disabled={buildData.isInstallingSoftware}
        >
          Clear Motion Plan Cache
        </MenuItem>

        {enableDeveloperAPI && (
          <MenuItem
            onClick={() => setModal('developerAPI')}
            iconKind="programVariable"
          >
            Configure developer API
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            // dispatch an event that the iPad app can detect
            document.dispatchEvent(new Event('logout'));

            push(LINK_TO_LOGOUT_PAGE);
          }}
          iconKind="power"
          className="tw-text-red"
          data-testid="logout-button"
        >
          Logout
        </MenuItem>
      </Menu>

      <UserSettingsModal
        isOpen={modal === 'userSettings'}
        onClose={closeModal}
      />

      <RobotFormModal
        isOpen={modal === 'renameRobot'}
        onClose={closeModal}
        robot={robot}
      />

      <UpdatePINModal isOpen={modal === 'pin'} onClose={closeModal} />

      <SafetySettingsModal
        isOpen={modal === 'safetySettings'}
        onClose={closeModal}
        robot={robot}
      />

      <DataBackupModal
        isOpen={modal === 'dataBackups'}
        robotID={robot.id}
        onClose={closeModal}
      />

      {modal === 'software' && (
        <SoftwareUpdateModal
          isOpen={modal === 'software'}
          onClose={closeModal}
          buildData={buildData}
          firmwareBuildData={firmwareBuildData}
        />
      )}

      <DeveloperAPIModal
        isOpen={modal === 'developerAPI'}
        onClose={closeModal}
        robot={robot}
      />

      {modal === 'factoryReset' && (
        <FactoryResetModal
          isOpen={modal === 'factoryReset'}
          onClose={closeModal}
        />
      )}
    </>
  );
}
