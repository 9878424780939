import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Tooltip,
  NavigationBar,
  NavigationBarButton,
  SettingsGroupItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';
import type { DistanceUnit, User } from '@sb/remote-control/types';
import {
  setLocalFeatureFlag,
  useLocalFeatureFlag,
  useSharedFeatureFlag,
  useToast,
} from '@sbrc/hooks';
import { updateUser } from '@sbrc/services';

import { SelectDistanceUnit } from '../units';

interface UserSettingsFormProps {
  initialSettings: User.ConvertedResponse;
  onClose: (forceClose?: boolean) => void;
  setHasChanges: (hasChanges: boolean) => void;
}

export function UserSettingsForm({
  initialSettings,
  onClose,
  setHasChanges,
}: UserSettingsFormProps) {
  const { setToast } = useToast();
  const [settings, setSettings] = useState(initialSettings);

  const isDisabledDarkMode = !!useSharedFeatureFlag('darkMode');
  const isDarkMode = !!useLocalFeatureFlag('darkMode');

  const hasChanges = !isEqual(settings, initialSettings);

  useEffect(() => setHasChanges(hasChanges), [hasChanges, setHasChanges]);

  const handleSelectDistanceUnit = async (distanceUnit: DistanceUnit) => {
    setSettings({ ...settings, distanceUnit });

    try {
      await updateUser(initialSettings.id, {
        distanceUnit,
      });

      setToast({ kind: 'success', message: 'User settings updated' });
    } catch (error) {
      setToast({
        kind: 'error',
        message: `User settings update failed: ${error}`,
      });
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={() => onClose()}>
            Cancel
          </NavigationBarButton>
        }
      >
        User settings
      </NavigationBar>

      <div className="tw-w-512 tw-h-[300px] tw-p-24">
        <div className="tw-flex tw-flex-col tw-gap-8">
          <SettingsGroupItem isSeparated>
            <span className="tw-flex-1">Units</span>
            <SelectDistanceUnit
              value={settings.distanceUnit}
              onSelect={(distanceUnit) =>
                handleSelectDistanceUnit(distanceUnit)
              }
            />
          </SettingsGroupItem>
          <Tooltip
            delayMS={5}
            isOpen={isDisabledDarkMode}
            content="Dark mode is set globally"
          >
            <SettingsGroupSwitchItem
              isSeparated
              label="Dark mode"
              disabled={isDisabledDarkMode}
              checked={isDarkMode}
              onChange={(e) =>
                setLocalFeatureFlag('darkMode', e.target.checked)
              }
            />
          </Tooltip>
        </div>
      </div>
    </form>
  );
}
