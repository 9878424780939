import {
  Icon,
  Menu,
  MenuItem,
  SettingsGroupItem,
  SettingsGroupMenuButton,
  useConfirmModal,
} from '@sb/design-system';

interface CameraStreamSourceItemProps {
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  onDelete: () => void;
}

export function CameraStreamSourceItem({
  name,
  isSelected,
  onSelect,
  onDelete,
}: CameraStreamSourceItemProps) {
  const [confirmModal, openConfirmModal] = useConfirmModal({
    body: 'Are you sure you want to delete this file?',
    confirmLabel: 'Delete',
    onConfirm: () => onDelete(),
  });

  return (
    <SettingsGroupItem onClick={onSelect}>
      <Icon kind={isSelected ? 'checkmark' : 'blank'} />

      <span className="tw-flex-1">{name}</span>

      <SettingsGroupMenuButton
        content={
          <Menu onClick={(e) => e.stopPropagation()}>
            <MenuItem iconKind="trash" onClick={openConfirmModal}>
              Delete
            </MenuItem>
          </Menu>
        }
      />

      {confirmModal}
    </SettingsGroupItem>
  );
}
