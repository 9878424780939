import { Icon } from '@sb/design-system';
import type { Integrations } from '@sb/remote-control/types';

import type { OnRobotScrewdriverState } from '../types';

interface OnRobotScrewdriverHomeWidgetInnerProps {
  routineRunnerGripperState: OnRobotScrewdriverState;
  args: Integrations.HomeWidgetProps;
}

export function OnRobotScrewdriverHomeWidgetInner({
  routineRunnerGripperState,
  args,
}: OnRobotScrewdriverHomeWidgetInnerProps) {
  const { implementation: onRobotScrewdriverImplementation } = args;

  const summaryStatus =
    onRobotScrewdriverImplementation.getSummaryStatus &&
    onRobotScrewdriverImplementation.getSummaryStatus(
      routineRunnerGripperState,
    );

  const getGripperStatus = () => {
    if (summaryStatus === 'busy' || summaryStatus === 'idle') {
      return <p className="tw-text-blue-30">Connected</p>;
    }

    if (
      summaryStatus === 'disconnected' ||
      (summaryStatus && summaryStatus.kind === 'error')
    ) {
      return <p className="tw-text-red-30">Not connected</p>;
    }

    return null;
  };

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-blue tw-max-w-[186px]">
            {onRobotScrewdriverImplementation.getDisplayName()}
          </h3>
        </div>
        <div className="tw-mb-8">{getGripperStatus()}</div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-w-[132px] tw-h-[132px] tw-rounded-18 tw-surface-elevated">
        <Icon
          kind={onRobotScrewdriverImplementation.iconKind}
          className="tw-icon-48 tw-text-label-tertiary"
        />
      </div>
    </>
  );
}
