import IOBadge from './IOBadge';

import styles from './IOLabel.module.css';

interface IOLabelProps {
  badgeLabel: string;
  assignedTo?: string;
  name: string;
}

const IOLabel = ({ badgeLabel, assignedTo, name }: IOLabelProps) => {
  return (
    <div className={styles.ioLabel}>
      <IOBadge className={styles.ioBadge}>{badgeLabel}</IOBadge>
      {assignedTo && <span className={styles.ioDeviceName}>{assignedTo}:</span>}
      {name}
    </div>
  );
};

export default IOLabel;
