import { shallow } from 'zustand/shallow';

import {
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroup,
} from '@sb/design-system';

import { SelectPort } from './SelectPort';
import { useExternalControlModalStore } from './store';

export function ExternalControl24VIO() {
  const [setCurrentView, setField, ioPorts] = useExternalControlModalStore(
    (s) => [s.setCurrentView, s.setField, s.settings.ioPorts],
    shallow,
  );

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarBackButton onClick={() => setCurrentView('summary')}>
            Back
          </NavigationBarBackButton>
        }
      >
        External control
      </NavigationBar>

      <div className="tw-p-24 tw-pt-12 tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-32">
        <p className="tw-text-13 tw-text-label-tertiary tw-text-center">
          You can assign control functions to I/O ports below.
          <br />
          The functions will only work when the robot is in External mode.
        </p>

        <section>
          <h5 className="tw-heading-40 tw-pl-16">Inputs</h5>

          <SettingsGroup>
            <SelectPort
              kind="Input"
              label="Play"
              value={ioPorts.play}
              onChange={(value) => setField('ioPorts.play', value)}
            />
            <SelectPort
              kind="Input"
              label="Pause"
              value={ioPorts.pause}
              onChange={(value) => setField('ioPorts.pause', value)}
            />
            <SelectPort
              kind="Input"
              label="Load default routine"
              value={ioPorts.loadDefaultRoutine}
              onChange={(value) =>
                setField('ioPorts.loadDefaultRoutine', value)
              }
            />
          </SettingsGroup>
        </section>

        <section>
          <h5 className="tw-heading-40 tw-pl-16">Outputs</h5>

          <SettingsGroup>
            <SelectPort
              kind="Output"
              label="Ready"
              value={ioPorts.ready}
              onChange={(value) => setField('ioPorts.ready', value)}
            />
            <SelectPort
              kind="Output"
              label="Running"
              value={ioPorts.running}
              onChange={(value) => setField('ioPorts.running', value)}
            />
            <SelectPort
              kind="Output"
              label="Fault"
              value={ioPorts.fault}
              onChange={(value) => setField('ioPorts.fault', value)}
            />
            <SelectPort
              kind="Output"
              label="Default routine loaded"
              value={ioPorts.defaultRoutineLoaded}
              onChange={(value) =>
                setField('ioPorts.defaultRoutineLoaded', value)
              }
            />
          </SettingsGroup>
        </section>
      </div>
    </>
  );
}
