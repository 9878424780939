import { useEffect, useState } from 'react';

import type { CameraIntrinsics } from '@sb/integrations/types/cameraTypes';
import { wait } from '@sb/utilities';
import { useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

export function useCameraIntrinsics(): CameraIntrinsics | undefined {
  const routineRunnerHandle = useRoutineRunnerHandle({});
  const [intrinsics, setIntrinsics] = useState<CameraIntrinsics>();

  const { setToast } = useToast();

  useEffect(() => {
    let cancelled = false;

    const run = async () => {
      try {
        const result = await routineRunnerHandle.runVisionMethod({
          method: 'getIntrinsics',
        });

        if (result.method === 'getIntrinsics') {
          setIntrinsics(result.results);
        }
      } catch (error) {
        setToast({
          kind: 'error',
          message: `Get intrinsics failed: ${error}`,
        });

        await wait(2000);

        if (!cancelled) {
          run();
        }
      }
    };

    run();

    return () => {
      cancelled = true;
    };
  }, [routineRunnerHandle, setToast]);

  return intrinsics;
}
