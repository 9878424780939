import cx from 'classnames';
import { shallow } from 'zustand/shallow';

import {
  Icon,
  NavigationBar,
  NavigationBarButton,
  SettingsGroup,
  SettingsGroupNavigationItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';
import { isNotUndefined } from '@sb/utilities';

import { useExternalControlModalStore } from './store';

interface ExternalControlSummaryProps {
  onClose: () => void;
}

export function ExternalControlSummary({
  onClose,
}: ExternalControlSummaryProps) {
  const [
    setCurrentView,
    setField,
    isEnabled,
    is24VIOEnabled,
    is24VIOConfigured,
    defaultRoutineID,
    allRoutines,
  ] = useExternalControlModalStore(
    (s) => [
      s.setCurrentView,
      s.setField,
      s.settings.isEnabled,
      s.settings.is24VIOEnabled,
      Object.values(s.settings.ioPorts).some(isNotUndefined),
      s.settings.defaultRoutineID,
      s.allRoutines,
    ],
    shallow,
  );

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        External control
      </NavigationBar>

      <div className="tw-p-24 tw-pt-12 tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-32">
        <p className="tw-text-13 tw-text-label-tertiary tw-text-center">
          Once enabled, external control mode will be available and can be
          switched into to permit the external devices configured below to
          control the robot.
        </p>

        <SettingsGroupSwitchItem
          label="Enabled"
          isSeparated
          checked={isEnabled}
          onChange={(e) => setField('isEnabled', e.target.checked)}
        />

        <SettingsGroup>
          <SettingsGroupSwitchItem
            label={
              <div className="tw-flex tw-gap-12 tw-items-center">
                <Icon kind="powerplug" />
                <span>Control via 24V I/O</span>
              </div>
            }
            checked={is24VIOEnabled}
            onChange={(e) => setField('is24VIOEnabled', e.target.checked)}
          />

          <SettingsGroupNavigationItem
            label={
              <>
                <div
                  className={cx(
                    'tw-w-12 tw-h-12 tw-rounded-full tw-ml-8 tw-mr-12',
                    is24VIOConfigured
                      ? 'tw-bg-green'
                      : 'tw-bg-label-quaternary',
                  )}
                />
                <span>
                  {is24VIOConfigured ? 'Configured' : 'Not configured'}
                </span>
              </>
            }
            secondaryLabel="Settings"
            onClick={() => setCurrentView('24VIO')}
          />
        </SettingsGroup>

        <SettingsGroupNavigationItem
          label="Default routine"
          secondaryLabel={
            defaultRoutineID ? (
              <div className="tw-max-w-256">
                {allRoutines[defaultRoutineID]}
              </div>
            ) : (
              'Not set'
            )
          }
          isSeparated
          onClick={() => setCurrentView('defaultRoutine')}
        />
      </div>
    </>
  );
}
