import Image from 'next/image';

import type { Integrations } from '@sb/remote-control/types';

import type { DHState } from '../types';

interface HomeWidgetInnerProps {
  routineRunnerGripperState: DHState;
  args: Integrations.HomeWidgetProps;
}

export function HomeWidgetInner({
  routineRunnerGripperState,
  args,
}: HomeWidgetInnerProps) {
  const { implementation } = args;

  const getGripperStatus = () => {
    if (routineRunnerGripperState.isConnected) {
      return <p className="tw-text-blue-30">Connected</p>;
    }

    return <p className="tw-text-red-30">Not connected</p>;
  };

  const path = `/images/grippers/${implementation.imageSrc}`;

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-blue tw-max-w-[186px]">
            {implementation.getDisplayName()}
          </h3>

          <p>{Math.round(routineRunnerGripperState.width * 1000)}mm width</p>
        </div>
        <div className="tw-mb-8">{getGripperStatus()}</div>
      </div>
      <Image
        src={path}
        alt={implementation.getDisplayName()}
        width="132"
        height="132"
      />
    </>
  );
}
