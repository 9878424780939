import cx from 'classnames';
import { useMemo, useRef } from 'react';

import type { IconKind } from '@sb/design-system';
import { Button, Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { convertMsToHHMMSS } from '@sb/utilities';
import {
  useIsRobotPaused,
  usePlayRobot,
  useRobotCycleStats,
  useRobotStateKind,
  useStopRobot,
} from '@sbrc/hooks';

import {
  WaitForConfirmationModal,
  type WaitForConfirmationModalRef,
} from '../notifications';

interface PlayPausePlayerProps {
  robot: Robot.ConvertedResponse;
  routineName: string;
  className?: string;
  children?: React.ReactNode;
}

export function PlayPausePlayer({
  robot,
  routineName,
  className,
  children,
}: PlayPausePlayerProps) {
  // routine data
  const routineRunnerKind = useRobotStateKind({});
  const isPaused = useIsRobotPaused({});
  const { stopRobot } = useStopRobot({});
  const isRoutinePlaying = routineRunnerKind === 'RoutineRunning' && !isPaused;

  const { onPlay, onPause } = usePlayRobot({ robotID: robot.id });

  const waitForConfirmationModalRef = useRef<WaitForConfirmationModalRef>(null);

  const onPauseButtonClick = () => {
    if (waitForConfirmationModalRef.current?.isWaitingForConfirmation) {
      waitForConfirmationModalRef.current.open();

      return;
    }

    if (isRoutinePlaying) {
      onPause();

      return;
    }

    onPlay();
  };

  const cycleStats = useRobotCycleStats({});
  const cycleCount: number = cycleStats?.cycleCount ?? 0;

  const totalRuntime: string = useMemo(() => {
    if (!cycleStats?.runTimeSeconds) {
      return '-';
    }

    return convertMsToHHMMSS(cycleStats.runTimeSeconds * 1000);
  }, [cycleStats?.runTimeSeconds]);

  const cycles = useMemo(() => {
    if (!cycleStats?.runTimeSeconds) {
      return '-';
    }

    return cycleCount;
  }, [cycleCount, cycleStats?.runTimeSeconds]);

  const cycleColumns: { kind: IconKind; value: string | number }[] = [
    { kind: 'clock', value: totalRuntime },
    { kind: 'arrowCirclePath', value: cycles },
  ];

  return (
    <>
      <div className={cx('tw-flex tw-items-center', className)}>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-text-ellipsis">
          <div className="first-letter:tw-uppercase">{routineName}</div>
          <div className="tw-flex tw-gap-12">
            {cycleColumns.map((column) => (
              <div
                key={column.kind}
                className="tw-flex tw-gap-4 tw-text-label-secondary"
              >
                <Icon kind={column.kind} className="tw-icon-18" />
                <p className="tw-text-13 tw-mt-4">{column.value}</p>
              </div>
            ))}
          </div>
        </div>

        {!isPaused && (
          <Button
            variant="Flat"
            color="Primary"
            size={44}
            aria-label="Pause"
            onClick={onPauseButtonClick}
            className="tw-icon-28"
            data-testid="app-header-pause-button"
          >
            <Icon kind="pauseFill" />
          </Button>
        )}

        {isPaused && (
          <Button
            variant="Flat"
            color="Primary"
            size={44}
            aria-label="Play"
            onClick={onPauseButtonClick}
            className="tw-icon-28"
            data-testid="app-header-play-button"
          >
            <Icon kind="playFill" />
          </Button>
        )}

        <Button
          variant="Flat"
          color="Primary"
          size={44}
          aria-label="Stop"
          onClick={() => stopRobot()}
          className="tw-icon-28"
        >
          <Icon kind="stopFill" />
        </Button>

        {children}
      </div>

      <WaitForConfirmationModal
        ref={waitForConfirmationModalRef}
        robotID={robot.id}
        shouldOpenAutomatically
      />
    </>
  );
}
