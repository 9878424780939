/* eslint-disable react/destructuring-assignment */

import Image from 'next/image';

import type { Integrations } from '@sb/remote-control/types';

export function CustomGripperHomeWidget(args: Integrations.HomeWidgetProps) {
  const { implementation } = args;

  const path = `/images/grippers/${implementation.imageSrc}`;

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-blue tw-max-w-[186px]">
            {implementation.getDisplayName()}
          </h3>
        </div>
      </div>
      <Image
        src={path}
        alt={implementation.getManufacturerName()}
        width="132"
        height="132"
      />
    </>
  );
}
