import { SettingsGroupSelectItem } from '@sb/design-system';
import type { CameraIntegration } from '@sb/integrations/types/cameraTypes';

import { DEFAULT_WRIST_CAMERA } from './wrist-camera/consts';

const CAMERAS: CameraIntegration[] = [DEFAULT_WRIST_CAMERA];

interface SelectCameraProps {
  value: CameraIntegration['kind'];
  onChange: (camera: CameraIntegration) => void;
}

export function SelectCamera({ value, onChange }: SelectCameraProps) {
  return (
    <SettingsGroupSelectItem
      label="Camera"
      value={value}
      onChange={(e) => {
        const newCamera = CAMERAS.find((c) => c.kind === e.target.value);

        if (newCamera) {
          onChange(newCamera);
        }
      }}
    >
      {CAMERAS.map((camera) => (
        <option key={camera.kind} value={camera.kind}>
          {camera.name}
        </option>
      ))}
    </SettingsGroupSelectItem>
  );
}
