import { useCallback, useState } from 'react';

import { useGuidedMode, useRoutineRunnerHandle, useToast } from '@sbrc/hooks';

import type { DHKind, DHCommand } from '../types/DHUnionTypes';

type Args = {
  isVizbot: boolean;
  kind: DHKind;
};

export const useGripperControl = (args: Args) => {
  const { isVizbot, kind } = args;
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const [command, setCommand] = useState<DHCommand>({
    kind: `${kind}Command`,
    targetForce: 0.2,
    targetDiameter: 0,
    targetSpeed: 0.5,
    waitForGripToContinue: false,
  });

  const setTargetWidth = useCallback(
    (targetWidth: number) => {
      setCommand((prev) => ({
        ...prev,
        targetDiameter: targetWidth,
      }));
    },
    [setCommand],
  );

  const targetWidth = command.targetDiameter;

  const { setToast } = useToast();

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({
    isVizbot,
    shouldStopBeforeRun: false,
  });

  const onHoldApplyChangesButton = useCallback(() => {
    runAdHocCommand({
      onRunCommand: async () => {
        const actuateGripper = await routineRunnerHandle.actuateDevice({
          command,
          payload: {
            mass: 0,
          },
        });

        return actuateGripper;
      },
      onError: (error) => {
        setToast({
          kind: 'error',
          message: `Error actuating gripper: ${error}`,
        });
      },
    });
  }, [command, runAdHocCommand, routineRunnerHandle, setToast]);

  return {
    targetWidth,
    setTargetWidth,
    stopGuidedMode,
    onHoldApplyChangesButton,
  };
};
