import type { Integrations } from '@sb/remote-control/types';

import { getGripperImplementationDefaults } from '../../utils/frontend/getGripperImplementationDefaults';

import { CustomGripperEquipmentManager } from './equipment-manager/CustomGripperEquipmentManager';
import { CustomGripperConfiguration } from './types';
import { CustomGripperHomeWidget } from './widget/CustomGripperHomeWidget';

const DEFAULT_NAME = 'Custom Gripper';

export const customGripperImplementation: Integrations.IntegrationFrontend<'CustomGripper'> =
  {
    ...getGripperImplementationDefaults(),
    iconKind: 'equipment3FingerGripper',
    imageSrc: 'CustomGripper.png',
    getSupportedSteps: () => [],
    getDisplayName: () => DEFAULT_NAME,
    getManufacturerName: () => 'Custom',
    getShortName: () => DEFAULT_NAME,
    getIntegrationVariables: () => [],
    getDefaultItem: () =>
      CustomGripperConfiguration.parse({ kind: 'CustomGripper' }),
    HomeWidget: CustomGripperHomeWidget,
    EquipmentManagerEditForm: CustomGripperEquipmentManager,
    getTCPOffsetOptions: (config) => {
      if (config.kind === 'CustomGripper') {
        return config.tooltips.map((tooltip, index) => ({
          tcpOffsetOption: index + 1,
          name: tooltip.name,
          iconKind: 'equipment3FingerGripper',
        }));
      }

      return [];
    },
  };
