import cx from 'classnames';

import type { Robot } from '@sb/remote-control/types';
import type { FirmwareBuildData } from '@sbrc/hooks';
import { useFeatureFlag, type RobotBadgeStatus } from '@sbrc/hooks';

import type { SoftwareBuildData } from '../software-update';

import { SpeedBadge } from './SpeedBadge';

interface RobotBadgeContentProps {
  robot: Robot.ConvertedResponse;
  robotBadgeStatus: RobotBadgeStatus;
  buildData: SoftwareBuildData;
  firmwareBuildData: FirmwareBuildData;
}

export default function RobotBadgeContent({
  robot,
  robotBadgeStatus,
  buildData,
  firmwareBuildData,
}: RobotBadgeContentProps) {
  const enableFirmwareMismatch = useFeatureFlag('enableFirmwareMismatch');

  const {
    shouldIndicateFirmwareExcludingIOMismatch,
    isJointInstallInProgress,
  } = firmwareBuildData;

  const isFirmwareMismatched =
    enableFirmwareMismatch &&
    shouldIndicateFirmwareExcludingIOMismatch &&
    !buildData.isSoftwareUpdateAvailable;

  const robotBadgeIndicator = (() => {
    if (buildData.isSoftwareUpdateAvailable) {
      return 'tw-bg-blue';
    }

    if (isFirmwareMismatched) {
      return 'tw-bg-red';
    }

    return robotBadgeStatus.ledClassName;
  })();

  return (
    <div
      className={cx(
        'tw-flex tw-flex-1 tw-items-center tw-gap-4 tw-h-36 tw-px-[14px] -tw-mx-[14px]',
        robotBadgeStatus.labelBgClassName,
      )}
    >
      {(robotBadgeStatus.kind === 'disconnected' ||
        robotBadgeStatus.kind === 'loading' ||
        buildData.isSoftwareUpdateAvailable ||
        (shouldIndicateFirmwareExcludingIOMismatch &&
          !buildData.isSoftwareUpdateAvailable)) && (
        <div
          className={cx(
            'tw-h-12',
            'tw-w-12',
            'tw-mr-4',
            'tw-rounded-full',
            'tw-text-ellipsis',
            robotBadgeIndicator,
          )}
        />
      )}

      <span
        className={cx('tw-text-label-primary', 'tw-flex-1', 'tw-text-left')}
      >
        {robot.name}
      </span>

      {buildData.isInstallingSoftware ||
        (isJointInstallInProgress && (
          <span className={cx('tw-flex-initial', 'tw-text-blue')}>
            Installing
          </span>
        ))}

      {robotBadgeStatus.kind !== 'idle' && (
        <span
          className={cx('tw-flex-initial', robotBadgeStatus.labelClassName)}
        >
          {robotBadgeStatus.label}
        </span>
      )}

      <SpeedBadge
        hidden={
          buildData.isInstallingSoftware ||
          robotBadgeStatus.kind === 'disconnected' ||
          robotBadgeStatus.kind === 'loading' ||
          robotBadgeStatus.kind === 'failed' ||
          robotBadgeStatus.kind === 'paused' ||
          robotBadgeStatus.kind === 'estop'
        }
        showSpeed={robotBadgeStatus.kind === 'running'}
        speedRestrictionPercentage={robot.speedRestrictionPercentage}
        isSlowSpeed={robotBadgeStatus.safeguardState === 'slowSpeed'}
      />
    </div>
  );
}
