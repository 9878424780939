import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState } from '@sbrc/hooks';

import { OnRobotScrewdriverHomeWidgetInner } from './OnRobotScrewdriverHomeWidgetInner';

export function OnRobotScrewdriverHomeWidget(
  args: Integrations.HomeWidgetProps,
) {
  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot: false },
    'OnRobotScrewdriver',
  );

  if (!routineRunnerGripperState) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a Screwdriver
        gripper is properly mounted and connected.
      </p>
    );
  }

  return (
    <OnRobotScrewdriverHomeWidgetInner
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
    />
  );
}
