import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState } from '@sbrc/hooks';

import type { DHKind } from '../types/DHUnionTypes';

import { HomeWidgetInner } from './HomeWidgetInner';

export const makeHomeWidget = <T extends DHKind>(kind: T) =>
  function DHBaseHomeWidget(args: Integrations.HomeWidgetProps) {
    const state = useRobotGripperState<DHKind>({ isVizbot: false }, kind);

    if (!state) {
      return (
        <p>
          The gripper does not appear to be connected. Please ensure a
          DHAG105145 gripper is properly mounted and connected.
        </p>
      );
    }

    return <HomeWidgetInner routineRunnerGripperState={state} args={args} />;
  };
