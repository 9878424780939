/* eslint-disable react/destructuring-assignment */
import Image from 'next/image';

import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState, useRobotPayloadState } from '@sbrc/hooks';

import { DualOnRobot2FG7HomeWidget } from './DualOnRobot2FG7HomeWidget';
import { DualOnRobot3FG15HomeWidget } from './DualOnRobot3FG15HomeWidget';

export function OnRobotDualQuickChangerHomeWidget(
  args: Integrations.HomeWidgetProps,
) {
  const { implementation } = args;
  const routineRunnerPayload = useRobotPayloadState({});

  const routineRunnerDualChangerState = useRobotGripperState(
    { isVizbot: false },
    'OnRobotDualQuickChanger',
  );

  const summaryStatus =
    implementation.getSummaryStatus &&
    routineRunnerDualChangerState &&
    implementation.getSummaryStatus(routineRunnerDualChangerState);

  if (!routineRunnerDualChangerState) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a Dual Quick
        Changer is properly mounted and connected.
      </p>
    );
  }

  if (
    routineRunnerDualChangerState.primary &&
    routineRunnerDualChangerState.primary.kind === 'OnRobot3FG15'
  ) {
    return (
      <DualOnRobot3FG15HomeWidget
        args={args}
        routineRunnerPayload={routineRunnerPayload}
        routineRunnerGripperState={routineRunnerDualChangerState.primary}
        summaryStatus={summaryStatus}
      />
    );
  }

  if (
    routineRunnerDualChangerState.primary &&
    routineRunnerDualChangerState.primary.kind === 'OnRobot2FG7'
  ) {
    return (
      <DualOnRobot2FG7HomeWidget
        args={args}
        routineRunnerPayload={routineRunnerPayload}
        routineRunnerGripperState={routineRunnerDualChangerState.primary}
        summaryStatus={summaryStatus}
      />
    );
  }

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-pt-8">
        <p className="tw-text-label-tertiary">
          Configure a gripper in the equipment manager
        </p>
      </div>
      <Image
        src="/images/grippers/NoGripper.png"
        alt="NoGripper"
        width="132"
        height="132"
      />
    </>
  );
}
