/* eslint-disable react/destructuring-assignment */

import type { Integrations } from '@sb/remote-control/types';
import { useRobotGripperState, useRobotPayloadState } from '@sbrc/hooks';

import { OnRobot2FG7HomeWidgetInner } from './OnRobot2FG7HomeWidgetInner';

export function OnRobot2FG7HomeWidget(args: Integrations.HomeWidgetProps) {
  const routineRunnerPayload = useRobotPayloadState({});

  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot: false },
    'OnRobot2FG7',
  );

  if (!routineRunnerGripperState || routineRunnerPayload === null) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a 2FG7
        gripper is properly mounted and connected.
      </p>
    );
  }

  return (
    <OnRobot2FG7HomeWidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
    />
  );
}
