import { useRouter } from 'next/router';

import { Button, Icon, NavigationBar } from '@sb/design-system';
import { LINK_TO_HOME_PAGE } from '@sbrc/utils';

export type Page = 'moveRobot' | 'routines' | 'robotList' | 'assemblyTools';

interface AppHeaderNavProps {
  className?: string;
}

export default function AppHeaderNav({ className }: AppHeaderNavProps) {
  const { push } = useRouter();

  return (
    <NavigationBar
      className={className}
      contentLeft={
        <Button
          size={44}
          variant="Flat"
          className="tw-pl-0 tw-pr-8 tw-icon-28 tw-gap-0"
          onClick={() => push(LINK_TO_HOME_PAGE)}
          data-testid="app-header-nav-move-robot-link"
        >
          <Icon kind="chevronLeft" className="-tw-ml-4" />
          <span>Home</span>
        </Button>
      }
    />
  );
}
