import { getGripperImplementationDefaults } from '@sb/integrations/utils/frontend/getGripperImplementationDefaults';
import type { Integrations, RoutineVariable } from '@sb/remote-control/types';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    {
      kind: 'gripperState',
      selector: 'gripperState.width.inner',
      variableKind: 'number',
      displayName: 'Inner width',
      variableName: 'kinematicState.gripperState.width.inner',
      userIdentifier: 'Gripper_width_inner',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.width.outer',
      variableKind: 'number',
      displayName: 'Outer width',
      variableName: 'kinematicState.gripperState.width.outer',
      userIdentifier: 'Gripper_width_outer',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
  ];

export const getDHCommon = (): Pick<
  Integrations.IntegrationFrontend<'NoGripper'>,
  | 'getIntegrationType'
  | 'canAddAsNewIntegration'
  | 'getSummaryStatus'
  | 'iconKind'
  | 'getSupportedSteps'
  | 'getManufacturerName'
  | 'getTCPOffsetOptions'
  | 'getIntegrationVariables'
  | 'EquipmentManagerEditForm'
> => ({
  ...getGripperImplementationDefaults(),
  iconKind: 'equipment2FingerGripper',
  getSupportedSteps: () => ['ActuateGripper'],
  getManufacturerName: () => {
    return 'OnRobot';
  },
  getIntegrationVariables: () => INTEGRATION_VARIABLES,
  EquipmentManagerEditForm: () => null,
  getTCPOffsetOptions: (config) => {
    return [
      {
        tcpOffsetOption: 1,
        name: config.name,
        iconKind: 'equipment2FingerGripper',
      },
    ];
  },
});
