import { shallow } from 'zustand/shallow';

import { SettingsGroupSelectItem } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  EXTERNAL_TO_ROBOT_PORT_NAMES,
  ROBOT_TO_EXTERNAL_PORT_NAMES,
  type ExternalToRobotPort,
  type RobotToExternalPort,
} from '@sb/routine-runner';

import { useExternalControlModalStore } from './store';

interface SelectPortPropsInput {
  kind: 'Input';
  label: React.ReactNode;
  value: ExternalToRobotPort | undefined;
  onChange: (value: ExternalToRobotPort | undefined) => void;
}

interface SelectPortPropsOutput {
  kind: 'Output';
  label: React.ReactNode;
  value: RobotToExternalPort | undefined;
  onChange: (value: RobotToExternalPort | undefined) => void;
}

export function SelectPort({
  kind,
  label,
  value,
  onChange,
}: SelectPortPropsInput | SelectPortPropsOutput) {
  const [ioPorts, robotIOPorts] = useExternalControlModalStore(
    (s) => [s.settings.ioPorts, kind === 'Input' ? s.ioInputs : s.ioOutputs],
    shallow,
  );

  const portNames =
    kind === 'Input'
      ? EXTERNAL_TO_ROBOT_PORT_NAMES
      : ROBOT_TO_EXTERNAL_PORT_NAMES;

  const getOptionProps = (portName: string, port: Robot.IOPort) => {
    const description =
      port.kind === 'Input' ? `IN ${port.port}` : `OUT ${port.port}`;

    if (portName !== value) {
      if (port.assignedTo) {
        return {
          children: `${description}: Assigned to: ${port.assignedTo}`,
          disabled: true,
        };
      }

      // avoid clashing ports even when external control is switched off
      if (Object.values(ioPorts).includes(portName as never)) {
        return {
          children: `${description}: Assigned to: External control`,
          disabled: true,
        };
      }
    }

    return { children: description };
  };

  return (
    <SettingsGroupSelectItem
      label={label}
      value={value ?? 'UNASSIGNED'}
      onChange={(e) => {
        const newValue = e.target.value as never;

        if (portNames.includes(newValue)) {
          onChange(newValue);
        } else {
          onChange(undefined);
        }
      }}
    >
      <option key="UNASSIGNED">Unassigned</option>

      {portNames.map((portName, index) => (
        <option
          key={portName}
          value={portName}
          {...getOptionProps(portName, robotIOPorts[index])}
        />
      ))}
    </SettingsGroupSelectItem>
  );
}
