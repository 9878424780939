import { shallow } from 'zustand/shallow';

import { MenuItem } from '@sb/design-system';
import { useFeatureFlag } from '@sbrc/hooks';

import { useExternalControlModalStore } from './store';

export function ExternalControlMenuItem() {
  const enableExternalControl24VIO = useFeatureFlag('externalControl24VIO');

  const [isEnabled, isInitialized, setIsOpen] = useExternalControlModalStore(
    (s) => [s.settings.isEnabled, s.robotID !== null, s.setIsOpen],
    shallow,
  );

  if (!enableExternalControl24VIO) {
    return null;
  }

  const indicator = isEnabled ? (
    <span className="tw-w-12 tw-h-12 tw-rounded-10 tw-inline-block tw-bg-green tw-mr-8" />
  ) : null;

  return (
    <MenuItem
      onClick={() => setIsOpen(true)}
      iconKind="rectangleLineBelow"
      disabled={!isInitialized}
    >
      {indicator}
      External control
    </MenuItem>
  );
}
