import Image from 'next/image';

import type { Integrations } from '@sb/remote-control/types';

import type { OnRobotVGP20State } from '../types';

interface OnRobotVGP20HomeWidgetInnerProps {
  routineRunnerGripperState: OnRobotVGP20State;
  args: Integrations.HomeWidgetProps;
}

export function OnRobotVGP20HomeWidgetInner({
  routineRunnerGripperState,
  args,
}: OnRobotVGP20HomeWidgetInnerProps) {
  const { implementation } = args;

  const summaryStatus =
    implementation.getSummaryStatus &&
    implementation.getSummaryStatus(routineRunnerGripperState);

  const getGripperStatus = () => {
    if (summaryStatus === 'busy' || summaryStatus === 'idle') {
      return <p className="tw-text-blue-30">Connected</p>;
    }

    if (
      summaryStatus === 'disconnected' ||
      (summaryStatus && summaryStatus.kind === 'error')
    ) {
      return <p className="tw-text-red-30">Not connected</p>;
    }

    return null;
  };

  const path = `/images/grippers/${implementation.imageSrc}`;

  return (
    <>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
        <div>
          <h3 className="tw-text-20 tw-font-medium tw-text-ellipsis tw-text-blue tw-max-w-[186px]">
            {implementation.getDisplayName()}
          </h3>
        </div>
        <div className="tw-mb-8">{getGripperStatus()}</div>
      </div>
      <Image
        src={path}
        alt={implementation.getManufacturerName()}
        width="132"
        height="132"
      />
    </>
  );
}
