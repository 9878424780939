import type { OnRobot2FG7State } from '@sb/integrations/implementations/OnRobot2FG7/types';
import type { Integrations } from '@sb/remote-control/types';

import { DualOnRobot2FG7HomeWidgetInner } from './DualOnRobot2FG7HomeWidgetInner';

interface DualOnRobot2FG7HomeWidgetProps {
  routineRunnerPayload: number | null;
  routineRunnerGripperState: OnRobot2FG7State;
  args: Integrations.HomeWidgetProps;
  summaryStatus: Integrations.SummaryStatus | null;
}

export function DualOnRobot2FG7HomeWidget({
  routineRunnerPayload,
  routineRunnerGripperState,
  args,
  summaryStatus,
}: DualOnRobot2FG7HomeWidgetProps) {
  if (
    !routineRunnerGripperState ||
    routineRunnerPayload === null ||
    summaryStatus === null
  ) {
    return (
      <p>
        The gripper does not appear to be connected. Please ensure a 2FG7
        gripper is properly mounted and connected.
      </p>
    );
  }

  return (
    <DualOnRobot2FG7HomeWidgetInner
      routineRunnerPayload={routineRunnerPayload}
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
      summaryStatus={summaryStatus}
    />
  );
}
