import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { Menu, MenuItem } from '@sb/design-system';
import { getEquipmentByKind } from '@sb/integrations/loaders/getEquipmentByKind';
import { getEquipmentList } from '@sb/integrations/loaders/getEquipmentList';
import type { DeviceKind } from '@sb/integrations/types';
import { canAddIntegration } from '@sb/integrations/utils/frontend/util';
import type { Equipment } from '@sb/remote-control/types';
import { useFeatureFlag } from '@sbrc/hooks';

const availableDeviceKinds = getEquipmentList();

interface NewEquipmentPickerProps {
  onAddEquipment: (equipment: Equipment.EditableFields) => void;
  equipment: Equipment.ConvertedResponse[];
}

export function NewEquipmentPicker({
  onAddEquipment,
  equipment,
}: NewEquipmentPickerProps) {
  const isCameraEnabled = useFeatureFlag('camera');
  const dhGrippersEnabled = useFeatureFlag('enableDHGrippers');

  const sortedAvailableEquipment = useMemo(() => {
    const withImplementation = availableDeviceKinds.map((kind) => ({
      kind,
      implementation: getEquipmentByKind(kind),
    }));

    const sorted = sortBy(withImplementation, (eq) =>
      eq.implementation.getDisplayName(),
    );

    return sorted.filter((eq) => {
      if (eq.kind === 'WristCamera') {
        return isCameraEnabled;
      }

      if (eq.kind === 'DHAG105145') {
        return dhGrippersEnabled;
      }

      return true;
    });
  }, [isCameraEnabled, dhGrippersEnabled]);

  const onAdd = (kindToAdd: DeviceKind) => {
    const isEnabled = canAddIntegration(
      kindToAdd,
      equipment,
      getEquipmentByKind,
    );

    const int = getEquipmentByKind(kindToAdd);

    const baseName = int.getShortName();

    const getDefaultName = (i = 1): string => {
      const name = i === 1 ? baseName : `${baseName} (${i})`;

      return equipment.some((e) => e.config.name === name)
        ? getDefaultName(i + 1)
        : name;
    };

    onAddEquipment({
      config: {
        ...int.getDefaultItem(),
        name: getDefaultName(),
      },
      isEnabled,
    });
  };

  return (
    <Menu className="tw-max-h-[90vh]">
      {sortedAvailableEquipment.map((device) => {
        return (
          <MenuItem
            key={device.kind}
            iconKind={device.implementation.iconKind}
            onClick={() => onAdd(device.kind)}
          >
            {device.implementation.getDisplayName()}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
