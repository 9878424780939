import type { Integrations } from '@sb/remote-control/types';

import { customGripperImplementation } from '../implementations/CustomGripper/frontend';
import { customIOImplementation } from '../implementations/CustomIO/frontend';
import { dhAG105145Frontend } from '../implementations/dh/instances/DHAG_105_145/frontend';
import { ewellixLiftTLTImplementation } from '../implementations/EwellixLiftTLT/frontend';
import { invalidConfigurationImplementation } from '../implementations/InvalidConfiguration/frontend';
import { modbusTCPServerImplementation } from '../implementations/ModbusTCPServer/frontend/frontend';
import { noGripperImplementation } from '../implementations/NoGripper/frontend';
import { onRobot2FG7Implementation } from '../implementations/OnRobot2FG7/frontend';
import { onRobot3FG15Implementation } from '../implementations/OnRobot3FG15/frontend';
import { onRobotDualQuickChangerImplementation } from '../implementations/OnRobotDualQuickChanger/frontend';
import { onRobotScrewdriverImplementation } from '../implementations/OnRobotScrewdriver/frontend';
import { onRobotVGP20Implementation } from '../implementations/OnRobotVGP20/frontend';
import { wristCameraImplementation } from '../implementations/WristCamera/frontend';

/**
 * Equipment front-end implementations for all equipment.
 * This is different than others as there is a circular dependency for
 * nested grippers (e.g. OnRobotDualQuickChanger).
 *
 * Feel free to separate out and refactor if you'd like :)
 */
export const getEquipmentByKind: Integrations.GetEquipmentByKind = (
  kind,
): Integrations.IntegrationFrontend<any> => {
  switch (kind) {
    case 'CustomIO':
      return customIOImplementation;
    case 'ModbusTCPServer':
      return modbusTCPServerImplementation;
    case 'EwellixLiftTLT':
      return ewellixLiftTLTImplementation;
    case 'OnRobot2FG7':
      return onRobot2FG7Implementation;
    case 'OnRobot3FG15':
      return onRobot3FG15Implementation;
    case 'OnRobotDualQuickChanger':
      return onRobotDualQuickChangerImplementation;
    case 'OnRobotScrewdriver':
      return onRobotScrewdriverImplementation;
    case 'OnRobotVGP20':
      return onRobotVGP20Implementation;
    case 'CustomGripper':
      return customGripperImplementation;
    case 'NoGripper':
      return noGripperImplementation;
    case 'WristCamera':
      return wristCameraImplementation;
    case 'DHAG105145':
      return dhAG105145Frontend;
    case 'InvalidConfiguration':
      return invalidConfigurationImplementation;
    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Unexpected kind for equipment ${exhaustive}`);
    }
  }
};
