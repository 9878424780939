import type { Integrations } from '@sb/remote-control/types/integrations';
import { useRobotGripperState } from '@sbrc/hooks';

import { OnRobotVGP20HomeWidgetInner } from './OnRobotVGP20HomeWidgetInner';

export function OnRobotVGP20HomeWidget(args: Integrations.HomeWidgetProps) {
  const routineRunnerGripperState = useRobotGripperState(
    { isVizbot: false },
    'OnRobotVGP20',
  );

  if (!routineRunnerGripperState) {
    return (
      <p>
        The vacuum does not appear to be connected. Please ensure an OnRobot
        VGP20 Vacuum is properly mounted and connected.
      </p>
    );
  }

  return (
    <OnRobotVGP20HomeWidgetInner
      routineRunnerGripperState={routineRunnerGripperState}
      args={args}
    />
  );
}
