import cx from 'classnames';
import { useState } from 'react';

import { Modal } from '@sb/design-system';
import { getEquipmentByKind } from '@sb/integrations/loaders/getEquipmentByKind';
import type { Equipment, Robot } from '@sb/remote-control/types';
import {
  useEquipment,
  useRobotGripperState,
  useRobotStateKind,
  useToast,
} from '@sbrc/hooks';
import {
  createEquipment,
  deleteEquipment,
  updateEquipment,
} from '@sbrc/services';

import { EditEquipment } from './EditEquipment';
import { EmptyState } from './EmptyState';
import { EquipmentList } from './EquipmentList';
import { LoadingState } from './LoadingState';
import { NewEquipmentPicker } from './NewEquipmentPicker';

interface EquipmentManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  robot: Robot.ConvertedResponse;
}

export function EquipmentManagerModal({
  isOpen,
  onClose,
  robot,
}: EquipmentManagerModalProps) {
  const isRoutineRunning = useRobotStateKind({}) === 'RoutineRunning';

  const equipment = useEquipment({ includeDisabled: true });

  const [selectedEquipment, setSelectedEquipment] =
    useState<Equipment.EditableFields | null>(null);

  const otherEquipment =
    equipment?.filter((e) => e.id !== selectedEquipment?.id) ?? [];

  const gripperState = useRobotGripperState({});

  const { setToast } = useToast();

  const renderModalContent = () => {
    if (!equipment) {
      return <LoadingState onClose={onClose} />;
    }

    const newEquipmentPicker = (
      <NewEquipmentPicker
        onAddEquipment={setSelectedEquipment}
        equipment={equipment}
      />
    );

    if (equipment.length === 0 && !selectedEquipment) {
      return (
        <EmptyState onClose={onClose} newEquipmentPicker={newEquipmentPicker} />
      );
    }

    if (!selectedEquipment) {
      return (
        <EquipmentList
          equipment={equipment}
          getSummaryStatus={(equipmentItem) => {
            if (
              equipmentItem.isEnabled &&
              gripperState?.kind === equipmentItem.config.kind
            ) {
              return getEquipmentByKind(gripperState.kind).getSummaryStatus?.(
                gripperState,
              );
            }

            return undefined;
          }}
          onClose={onClose}
          newEquipmentPicker={newEquipmentPicker}
          onSelect={setSelectedEquipment}
        />
      );
    }

    return (
      <EditEquipment
        isFormDisabled={isRoutineRunning}
        equipmentItem={selectedEquipment}
        otherEquipment={otherEquipment}
        robot={robot}
        onBack={() => setSelectedEquipment(null)}
        onSave={async (updatedEquipment) => {
          let { id } = selectedEquipment;

          if (id) {
            await updateEquipment(id, updatedEquipment);
          } else {
            id = await createEquipment(updatedEquipment);
          }

          setSelectedEquipment({ id, ...updatedEquipment });

          setToast({
            kind: 'success',
            message: 'Equipment item saved',
          });
        }}
        onDelete={async () => {
          if (selectedEquipment.id) {
            await deleteEquipment(selectedEquipment.id);
            setSelectedEquipment(null);
          }
        }}
        getEquipmentByKind={getEquipmentByKind}
        showEnabledSwitch
      />
    );
  };

  return (
    <Modal
      className={cx(
        'tw-w-512',
        'tw-h-[80vh]',
        'tw-flex',
        'tw-flex-col',
        'tw-overflow-visible',
      )}
      isOpen={isOpen}
      onClose={() => {
        if (!selectedEquipment) {
          onClose();
        }
      }}
    >
      {renderModalContent()}
    </Modal>
  );
}
